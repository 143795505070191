package adm.groups

import react.ChildrenBuilder
import react.MutableRefObject
import react.Ref
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import web.html.HTMLSelectElement

fun ChildrenBuilder.selectPaymentType(
    typeRef: Ref<HTMLSelectElement>,
    includeAny: Boolean = true,
    default: IUserToGroupConnectionInfoDto.PaymentType? = null,
) {
    select {
        ref = typeRef

        if (includeAny) {
            option {
                +"Any"
                value = null
            }
        }
        option {
            +"Free"
            value = IUserToGroupConnectionInfoDto.PaymentType.FREE.name
        }
        option {
            +"Paid"
            value = IUserToGroupConnectionInfoDto.PaymentType.PAID.name
        }

        defaultValue = default?.name
    }
}

fun ChildrenBuilder.selectPaymentState(
    stateRef: Ref<HTMLSelectElement>,
    includeAny: Boolean = true,
    default: IUserToGroupConnectionInfoDto.PaymentState? = null,
) {
    select {
        ref = stateRef

        if (includeAny) {
            option {
                +"Any"
                value = null
            }
        }
        option {
            +"Paid"
            value = IUserToGroupConnectionInfoDto.PaymentState.PAID.name
        }
        option {
            +"Not paid"
            value = IUserToGroupConnectionInfoDto.PaymentState.NOT_PAID.name
        }

        defaultValue = default?.name
    }
}

fun ChildrenBuilder.selectStudentsGroupType(
    typeRef: Ref<HTMLSelectElement>,
    includeAny: Boolean = true,
    default: StudentsGroupTypeDto? = null,
) {
    select {
        ref = typeRef

        if (includeAny) {
            option {
                +"Any"
                value = null
            }
        }
        option {
            +"Course"
            value = StudentsGroupTypeDto.COURSE.name
        }

        defaultValue = default?.name
    }
}

fun ChildrenBuilder.selectStudentsGroupState(
    stateRef: Ref<HTMLSelectElement>,
    includeAny: Boolean = true,
    default: StudentsGroupStateDto? = null,
) {
    select {
        ref = stateRef

        if (includeAny) {
            option {
                +"Any"
                value = null
            }
        }
        option {
            +"Active"
            value = StudentsGroupStateDto.ACTIVE.name
        }
        option {
            +"Inactive"
            value = StudentsGroupStateDto.INACTIVE.name
        }
        option {
            +"Archived"
            value = StudentsGroupStateDto.ARCHIVED.name
        }

        defaultValue = default?.name
    }
}

fun paymentTypeRefToDto(typeRef: MutableRefObject<HTMLSelectElement>): IUserToGroupConnectionInfoDto.PaymentType? {
    try {
        return IUserToGroupConnectionInfoDto.PaymentType.valueOf(typeRef.current?.value ?: return null)
    } catch (e: IllegalStateException) {
        return null
    }
}

fun paymentStateRefToDto(stateRef: MutableRefObject<HTMLSelectElement>): IUserToGroupConnectionInfoDto.PaymentState? {
    try {
        return IUserToGroupConnectionInfoDto.PaymentState.valueOf(stateRef.current?.value ?: return null)
    } catch (e: IllegalStateException) {
        return null
    }
}

fun studentsGroupStateRefToDto(stateRef: MutableRefObject<HTMLSelectElement>): StudentsGroupStateDto? {
    try {
        return StudentsGroupStateDto.valueOf(stateRef.current?.value ?: return null)
    } catch (e: IllegalStateException) {
        return null
    }
}

fun studentsGroupTypeRefToDto(typeRef: MutableRefObject<HTMLSelectElement>): StudentsGroupTypeDto? {
    try {
        return StudentsGroupTypeDto.valueOf(typeRef.current?.value ?: return null)
    } catch (e: IllegalStateException) {
        return null
    }
}
