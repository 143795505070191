package client

import adm.lessons.LessonDto
import adm.lessons.LessonToKnowledgeInfoWithKnowledgeBodyDto
import adm.lessons.LessonsApi
import adm.lessons.StudentLessonKnowledgeStatusDto
import emotion.react.css
import js.core.jso
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import org.w3c.dom.url.URLSearchParams
import react.*
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.main
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.title
import tasks.edit.KnowledgeDto
import utils.borderCss
import web.cssom.Color
import web.cssom.Cursor
import web.cssom.em
import web.html.HTMLInputElement
import web.location.location

class LessonComponent(
    private var lessonId: Long?,
) : Component<Props, State>() {
    override fun render(): ReactNode {
        return FC<Props> {
            var lesson: LessonDto? by useState(null)
            var knowledgeList: List<LessonToKnowledgeInfoWithKnowledgeBodyDto>? by useState(null)
            var knowledgePosition: Int by useState(1)
            var userToKnowledgeStatusDtos: List<StudentLessonKnowledgeStatusDto> by useState(emptyList())
            val answerRef = useRef<HTMLInputElement>()

            useEffectOnce {
                lessonId = URLSearchParams(location.search).get("lessonId")?.toLong()

                MainScope().launch {
                    val knowledgeFetchTask = async { LessonsApi.fetchLessonToKnowledgeInfos(lessonId!!) }
                    val lessonFetchJob = async { lesson = LessonsApi.fetchLesson(lessonId!!) }
                    val statuses = LessonsApi.fetchMyLessonKnowledgeStatus(lessonId!!)

                    val knowledges = knowledgeFetchTask.await()
                    statuses.maxByOrNull { it.updatedAt }?.let { lastStatus ->
                        knowledgePosition =
                            knowledges.firstOrNull { it.knowledge.id == lastStatus.knowledgeId }?.orderNumber
                                ?: 1
                    }

                    knowledgeList = knowledges
                    userToKnowledgeStatusDtos = statuses

                    lessonFetchJob.await()
                }
            }

            title {
                +"Урок"
            }

            fun incrementKnowledgeStatus(
                knowledge: LessonToKnowledgeInfoWithKnowledgeBodyDto,
                status: StudentLessonKnowledgeStatusDto.Status? = null,
            ) {
                MainScope().launch {
                    val newStatus = LessonsApi.incrementMyLessonKnowledgeStatus(
                        lessonId!!,
                        knowledge.knowledge.id!!,
                        status ?: StudentLessonKnowledgeStatusDto.Status.NOT_STARTED,
                    )

                    userToKnowledgeStatusDtos = userToKnowledgeStatusDtos
                        .filter { it.knowledgeId != newStatus.knowledgeId } + newStatus
                }
            }

            fun validateAnswer(
                knowledge: LessonToKnowledgeInfoWithKnowledgeBodyDto,
                answer: String,
            ) {
                MainScope().launch {
                    val newStatus = LessonsApi.validateAnswer(
                        lessonId!!,
                        knowledge.knowledge.id!!,
                        answer,
                    )

                    userToKnowledgeStatusDtos = userToKnowledgeStatusDtos
                        .filter { it.knowledgeId != newStatus.knowledgeId } + newStatus
                }
            }

            fun doSendAnswer(knowledgeDto: KnowledgeDto) {
                val answer = answerRef.current!!.value
                when (knowledgeDto.type) {
                    KnowledgeDto.Type.TASK_VISIBLE_ANSWER -> {
                        if (answer == knowledgeDto.answer) {
                            window.alert("Вы правы!!!")
                            incrementKnowledgeStatus(
                                knowledgeList!!.firstOrNull { it.orderNumber == knowledgePosition }
                                    ?: knowledgeList!!.first(),
                                StudentLessonKnowledgeStatusDto.Status.COMPLETED,
                            )
                        } else {
                            window.alert("Вы не правы!!! Ответ: ${knowledgeDto.answer}")
                            incrementKnowledgeStatus(
                                knowledgeList!!.firstOrNull { it.orderNumber == knowledgePosition }
                                    ?: knowledgeList!!.first(),
                                StudentLessonKnowledgeStatusDto.Status.IN_PROGRESS,
                            )
                        }
                    }

                    KnowledgeDto.Type.TASK_HIDDEN_ANSWER -> {
                        validateAnswer(
                            knowledgeList!!.firstOrNull { it.orderNumber == knowledgePosition }
                                ?: knowledgeList!!.first(),
                            answer,
                        )
                    }

                    else -> {
                        throw IllegalStateException("Unexpected value: ${knowledgeDto.type}")
                    }
                }
            }

            useEffect(knowledgePosition) {
                MainScope().launch {
                    knowledgeList
                        ?.firstOrNull { it.orderNumber == knowledgePosition }
                        ?.let { incrementKnowledgeStatus(it) }
                }

                answerRef.current?.value = ""
            }

            fun ChildrenBuilder.renderTaskProgressBar() {
                div {
                    borderCss()

                    for (knowledge in knowledgeList!!.sortedBy { it.orderNumber }) {
                        button {
                            css {
                                backgroundColor =
                                    when (userToKnowledgeStatusDtos.firstOrNull { it.knowledgeId == knowledge.knowledge.id }?.status) {
                                        StudentLessonKnowledgeStatusDto.Status.COMPLETED -> Color("green")
                                        StudentLessonKnowledgeStatusDto.Status.IN_PROGRESS -> Color("#cc5500")
                                        else -> null
                                    }
                                cursor = if (knowledgePosition == knowledge.orderNumber) Cursor.notAllowed else null
                                fontSize = 2.0.em
                            }

                            disabled = knowledgePosition == knowledge.orderNumber
                            +knowledge.orderNumber.toString()
                            onClick = {
                                knowledgePosition = knowledge.orderNumber
                            }
                        }
                    }
                }
            }

            fun ChildrenBuilder.renderAnswerBox(knowledgeDto: KnowledgeDto) {
                h3 { +"Ответ" }

                form {
                    onSubmit = {
                        it.preventDefault()
                        doSendAnswer(knowledgeDto)
                    }

                    input {
                        ref = answerRef
                    }
                }

                button {
                    +"Отправить"
                    onClick = {
                        doSendAnswer(knowledgeDto)
                    }
                }
            }

            fun ChildrenBuilder.renderKnowledge(knowledgeBodyDto: LessonToKnowledgeInfoWithKnowledgeBodyDto) {
                div {
                    h2 {
                        +"Задание №$knowledgePosition. "
                        +when (knowledgeBodyDto.knowledge.type) {
                            KnowledgeDto.Type.THEORY -> "Теория. "
                            KnowledgeDto.Type.TASK_VISIBLE_ANSWER -> "Задание с видимым ответом. "
                            KnowledgeDto.Type.TASK_HIDDEN_ANSWER -> "Задание с скрытым ответом. "
                        }
                    }
                    div {
                        dangerouslySetInnerHTML = jso {
                            __html = knowledgeBodyDto.knowledge.body
                        }
                    }

                    div {
                        if (knowledgeBodyDto.knowledge.type == KnowledgeDto.Type.THEORY) {
                            button {
                                +"Завершить"
                                onClick = {
                                    incrementKnowledgeStatus(
                                        knowledgeBodyDto,
                                        StudentLessonKnowledgeStatusDto.Status.COMPLETED,
                                    )
                                }
                            }
                        } else {
                            renderAnswerBox(knowledgeBodyDto.knowledge)
                        }
                    }

                    div {
                        if (knowledgePosition > 1) {
                            button {
                                +"Предыдущее задание"
                                onClick = {
                                    knowledgePosition--
                                }
                            }
                        }
                        if (knowledgePosition < knowledgeList!!.size) {
                            button {
                                +"Следующее задание"
                                onClick = {
                                    knowledgePosition++
                                }
                            }
                        }
                    }
                }
            }

            main {
                if (lesson == null || knowledgeList == null) {
                    +"Загрузка..."
                } else {
                    div {
                        h2 { +"Название:" }
                        p { +lesson!!.name }
                    }

                    if (lesson != null && lesson!!.type != LessonDto.Type.REGULAR) {
                        h2 { +"Невозможно открыть данный тип уроков на этой странице:" }
                    } else if (knowledgeList!!.isNotEmpty()) {
                        div {
                            borderCss()

                            renderTaskProgressBar()
                            renderKnowledge(
                                knowledgeList!!.firstOrNull { it.orderNumber == knowledgePosition }
                                    ?: knowledgeList!!.first(),
                            )
                        }
                    }
                }
            }
        }.create()
    }
}
