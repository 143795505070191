package adm.lessons

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.*
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import utils.fallbackIfNotTeacher
import web.html.HTMLInputElement
import web.html.HTMLSelectElement
import web.html.InputType
import web.location.location
import kotlin.js.Date

val LessonAddComponent = FC<Props> {
    val nameRef = useRef<HTMLInputElement>()
    val stateRef = useRef<HTMLSelectElement>()
    val publishAtRef = useRef<HTMLInputElement>()
    val taskNumberRef = useRef<HTMLInputElement>()
    val typeRef = useRef<HTMLSelectElement>()
    val timeToTestRef = useRef<HTMLInputElement>()
    var typeState: LessonDto.Type by useState(LessonDto.Type.REGULAR)

    useEffectOnce {
        MainScope().launch {
            fallbackIfNotTeacher()
        }
    }

    div {
        +"Name"

        input {
            ref = nameRef
        }
    }
    div {
        +"Publish at"

        input {
            type = InputType.datetimeLocal
            ref = publishAtRef
        }
    }
    div {
        +"Task number"

        input {
            type = InputType.number
            ref = taskNumberRef
        }
    }
    div {
        +"State"

        stateSelect(stateRef, false)
    }
    div {
        +"Type"

        typeSelect(typeRef, false) {
            typeState = lessonTypeRefToEnum(typeRef)!!
        }
    }
    if (lessonTypeRefToEnum(typeRef) == LessonDto.Type.TEST) {
        div {
            +"Time to test in minutes"

            timeToTestInput(timeToTestRef)
        }
    }

    button {
        +"Create"
        onClick = {
            MainScope().launch {
                val type = lessonTypeRefToEnum(typeRef)!!
                val payload = when (type) {
                    LessonDto.Type.REGULAR -> LessonPayloadDto.LessonPayloadStub
                    LessonDto.Type.TEST -> TestLessonPayloadDto(
                        timeToTest = timeToTestRef.current?.value?.toLong()?.let { it * 60_000 } ?: 0,
                    )
                }

                val lesson = LessonsApi.createLesson(
                    LessonDto(
                        id = null,
                        name = nameRef.current?.value ?: "",
                        publishAt = Date(publishAtRef.current?.value ?: "").getTime().toLong(),
                        taskNumber = taskNumberRef.current?.value?.toInt() ?: 0,
                        state = lessonStateRefToEnum(stateRef) ?: LessonDto.State.DRAFT,
                        type = type,
                        payload = payload,
                    ),
                )

                location.href = "/admin/lessons/edit/?id=${lesson.id}"
            }
        }
    }
}
