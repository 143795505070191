package auth

import adm.users.UserAdminApi
import emotion.react.css
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.main
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.title
import react.useRef
import react.useState
import utils.useActionAwayOverlay
import web.cssom.*
import web.html.HTMLInputElement
import web.html.InputType
import web.location.location

private val REGISTER_BUTTON_ID = "register-button"

val RegisterComponent =
    FC<Props> {
        val username = useRef<HTMLInputElement>()
        val password = useRef<HTMLInputElement>()
        var isErrorOverlayVisible: Boolean by useState(false)

        fun doRegister() {
            MainScope().launch {
                try {
                    UserAdminApi.createUser(username.current!!.value, password.current!!.value)
                } catch (e: Exception) {
                    isErrorOverlayVisible = true
                    return@launch
                }

                location.href = "/login"
            }
        }

        useActionAwayOverlay(
            "NOTHING",
            setOf(REGISTER_BUTTON_ID),
        ) { isErrorOverlayVisible = false }

        title {
            +"Регистрация"
        }

        div {
            className = if (isErrorOverlayVisible) ClassName("hidden-behind-overlay") else null

            main {
                css {
                    display = Display.flex
                    justifyContent = JustifyContent.center
                }

                div {
                    h1 {
                        +"Зарегистрироваться"
                    }

                    ReactHTML.form {
                        onSubmit = {
                            it.preventDefault()
                            doRegister()
                        }

                        div {
                            p {
                                +"Имя пользователя:"
                            }
                            div {
                                input {
                                    ref = username
                                }
                            }
                        }

                        div {
                            p {
                                +"Пароль:"
                            }
                            div {
                                input {
                                    type = InputType.password
                                    ref = password
                                }
                            }
                        }

                        button {
                            id = REGISTER_BUTTON_ID

                            +"Зарегистрироваться"
                            onClick = {
                                doRegister()
                            }
                        }
                    }
                }
            }
        }

        div {
            className = if (isErrorOverlayVisible) ClassName("overlay") else ClassName("overlay-hidden")

            div {
                className = ClassName("inner-overlay-div")

                div {
                    css {
                        color = Color("red")
                        textAlign = TextAlign.center
                        display = Display.flex
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                        fontSize = 3.em
                    }

                    p {
                        +"Ошибка регистрации"
                    }
                }
            }
        }
    }
