package adm.users

import auth.RoleDto
import react.ChildrenBuilder
import react.MutableRefObject
import react.Ref
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import web.html.HTMLSelectElement

fun ChildrenBuilder.roleSelector(
    roleRef: Ref<HTMLSelectElement>?,
) {
    div {
        +"Role: "

        select {
            option {
                value = null
                +"Any"
            }
            option {
                value = RoleDto.Role.ADMIN
                +"Admin"
            }
            option {
                value = RoleDto.Role.USER
                +"User"
            }
            option {
                value = RoleDto.Role.TEACHER
                +"Teacher"
            }
            option {
                value = RoleDto.Role.CURATOR
                +"Curator"
            }

            ref = roleRef
        }
    }
}

fun userRoleRefToEnum(roleRef: MutableRefObject<HTMLSelectElement>): RoleDto.Role? {
    return try {
        roleRef.current?.value?.let { RoleDto.Role.valueOf(it) }
    } catch (e: IllegalStateException) {
        null
    }
}
