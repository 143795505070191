package configuration

import kotlinx.browser.window
import kotlinx.serialization.json.Json

object Conf {
    val globalUrlBase: String
        inline get() =
            when {
                "bazaege.ru" in window.location.href -> "https://bazaege.ru/api"
                else -> "http://192.168.1.42:8080/api"
            }

    const val CURATOR_RELOAD_DELAY = 30_000L

    val json =
        Json {
            ignoreUnknownKeys = true
            classDiscriminator = "@type"
        }
}
