package adm.users

import auth.*
import configuration.Conf
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.serialization.encodeToString
import org.w3c.dom.url.URLSearchParams
import org.w3c.fetch.Headers
import org.w3c.fetch.RequestInit

object UserAdminApi {
    suspend fun getUsersContactDetailBatch(userIds: Collection<Long>): List<UserContactDetailsDto> {
        val search = URLSearchParams()
        userIds.forEach { search.append("userIds", it.toString()) }

        val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/users/contactDetailsBatch?$search")
            .text()
            .await()

        return Conf.json.decodeFromString(response)
    }

    suspend fun updateUser(userDto: UserWithPassDto) {
        authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/users/${userDto.id}",
            RequestInit(
                method = "PUT",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
                body = Conf.json.encodeToString(userDto),
            ),
        )
    }

    suspend fun createUser(username: String, password: String): Long {
        val response = window
            .fetch(
                "${Conf.globalUrlBase}/v0/users/register",
                RequestInit(
                    method = "POST",
                    headers = Headers().apply {
                        append("Content-Type", "application/json")
                    },
                    body = Conf.json.encodeToString(
                        UserWithPassDto(
                            username = username,
                            password = password,
                        ),
                    ),
                ),
            )
            .await()
            .text()
            .await()

        return Conf.json.decodeFromString<Long>(response)
    }

    suspend fun getUser(id: Long): UserDtoWithRoles {
        val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/users/$id")
            .text()
            .await()

        return Conf.json.decodeFromString<UserDtoWithRoles>(response)
    }

    suspend fun getContactDetails(userId: Long): List<UserContactDetailsDto> {
        val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/users/$userId/contact_details")
            .text()
            .await()

        return Conf.json.decodeFromString<List<UserContactDetailsDto>>(response)
    }

    suspend fun addRole(userId: Long, role: RoleDto.Role) {
        val body = Conf.json.encodeToString(role)
        console.log(body)

        authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/users/add_role?userId=$userId",
            RequestInit(
                method = "POST",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
                body = body,
            ),
        )
    }

    suspend fun deleteRole(userId: Long, role: RoleDto.Role) {
        authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/users/remove_role?userId=$userId",
            RequestInit(
                method = "POST",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
                body = Conf.json.encodeToString(role),
            ),
        )
    }

    suspend fun addUserContactInfo(userContactDetailsDto: UserContactDetailsDto) {
        authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/users/${userContactDetailsDto.userId}/contact_details",
            RequestInit(
                method = "POST",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
                body = Conf.json.encodeToString(userContactDetailsDto),
            ),
        )
    }

    suspend fun deleteUserContactInfo(userContactDetailsDto: UserContactDetailsDto) {
        authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/users/${userContactDetailsDto.userId}/contact_details/${userContactDetailsDto.id}",
            RequestInit(
                method = "DELETE",
            ),
        )
    }

    suspend fun getAllUsers(role: RoleDto.Role?, page: Int): List<UserDtoWithRoles> {
        val search = URLSearchParams()

        if (role != null) {
            search.append("role", role.name)
        }
        search.append("page", page.toString())

        val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/users/allUsers?$search")
            .text()
            .await()

        return Conf.json.decodeFromString<List<UserDtoWithRoles>>(response)
    }
}
