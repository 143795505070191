import auth.*
import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.*
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.nav
import react.router.Outlet
import react.router.dom.Link
import utils.experiments.ExperimentEntityTypeDto
import utils.experiments.ExperimentNamespaceDto
import utils.experiments.checkInExperiment
import web.cssom.*
import web.location.location

private inline fun PropsWithClassName.buttonCss() {
    css {
        border = Border(0.px, LineStyle.hidden)
        fontSize = 1.em
    }
}

private inline fun PropsWithClassName.linkCss() {
    css {
        color = Color("white")
    }
}

val NavigationComponent =
    FC<Props> {
        var displayAdminDropDown: Boolean by useState(false)
        var feedbackTabOn: Boolean by useState(false)

        nav {

//        linearGradient(Color("#141e30"), Color("#243b55"))
            css {
                display = Display.flex
                justifyContent = JustifyContent.spaceAround
                alignItems = AlignItems.center
                backgroundColor = Color("#243b55")
            }

            var user: UserDtoWithRoles? by useState(null)

            useEffectOnce {
                MainScope().launch {
                    val jobs =
                        listOf(
                            launch {
                                val userTmp =
                                    try {
                                        Api.me()
                                    } catch (e: Throwable) {
                                        UserDtoWithRoles(
                                            id = -1,
                                            username = "Guest",
                                            roles = emptyList(),
                                        )
                                    }

                                userDetailsStore.dispatch(UserDetailsAction(userTmp))

                                user = userTmp
                            },
                            launch {
                                feedbackTabOn =
                                    checkInExperiment(
                                        experimentName = ExperimentNamespaceDto.FEEDBACK_TAB,
                                        entityType = ExperimentEntityTypeDto.USER_ID,
                                        entityId = 0,
                                    )
                            },
                        )
                }
            }
            if (user == null) {
                div {
                    +"Loading..."
                }
            } else {
                if (user!!.id == (-1).toLong()) {
//                    if (window.location.pathname != "/login" && window.location.pathname != "/login/") {
//                        window.history.pushState(null, "", "/login")
//                        window.location.reload()
//                    }

                    Link {
                        linkCss()

                        to = "login/"

                        +"Вход"
                    }

//                Link {
//                    linkCss()
//
//                    to = "register/"
//
//                    +"Регистрация"
//                }
                    return@nav
                }

                val roles = user!!.roles.map { it.role }

                if ("/tasks/edit" !in window.location.pathname) {
                    Link {
                        linkCss()
                        to = "/groups/"
                        +"Мои группы"
                    }

                    if (RoleDto.Role.ADMIN in roles || RoleDto.Role.CURATOR in roles) {
                        Link {
                            linkCss()
                            to = "/curator/progress/"
                            +"Прогресс курируемых групп"
                        }
                    }

                    if (RoleDto.Role.ADMIN in roles || RoleDto.Role.TEACHER in roles) {
                        Link {
                            linkCss()
                            to = "/tasks/"
                            +"Tasks"
                        }
                        Link {
                            linkCss()
                            to = "/admin/lessons/"
                            +"Lessons"
                        }
                    }

                    if (RoleDto.Role.ADMIN in roles) {
                        div {
                            button {
                                buttonCss()

                                +"Admin"

                                onClick = {
                                    displayAdminDropDown = displayAdminDropDown xor true
                                }
                            }
                            if (displayAdminDropDown) {
                                div {
                                    css {
                                        display = Display.flex
                                        flexDirection = FlexDirection.column
//                                position = Position.absolute
//                                top = 3.em
//                                right = 0.em
                                        backgroundColor = Color("inherit")
                                    }

                                    Link {
                                        linkCss()
                                        to = "/admin/users/"
                                        +"Users"
                                    }
                                    Link {
                                        linkCss()
                                        to = "/admin/groups/"
                                        +"Groups"
                                    }
                                }
                            }
                        }
                    }
                    Link {
                        linkCss()
                        to = "/profile/"
                        +"Редактировать профиль"
                    }

                    if (RoleDto.Role.DEVELOPER in roles) {
                        div {
                            button {
                                buttonCss()

                                +"DevAdmin"

                                onClick = {
                                    displayAdminDropDown = displayAdminDropDown xor true
                                }
                            }

                            if (displayAdminDropDown) {
                                div {
                                    css {
                                        display = Display.flex
                                        flexDirection = FlexDirection.column
//                                position = Position.absolute
//                                top = 3.em
//                                right = 0.em
                                        backgroundColor = Color("#243b55")
                                    }

                                    button {
                                        +"Refresh tokens"

                                        onClick = {
                                            location.href = "/login/refreshTokens/"
                                        }
                                    }
                                    button {
                                        +"Switch admin"

                                        onClick = {
                                            MainScope().launch {
                                                Api.switchAdmin()
                                                location.href = "/login/refreshTokens/"
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (feedbackTabOn) {
                        Link {
                            linkCss()
                            to = "/feedback/"
                            +"Обратная связь"
                        }
                    }
                } else {
                    button {
                        buttonCss()
                        onClick = {
                            window.location.href = "/groups/"
                        }

                        +"Мои группы"
                    }

                    button {
                        buttonCss()
                        onClick = {
                            window.location.href = "/curator/progress/"
                        }

                        +"Прогресс курируемых групп"
                    }

                    if (RoleDto.Role.ADMIN in roles || RoleDto.Role.TEACHER in roles) {
                        button {
                            buttonCss()
                            onClick = {
                                window.location.href = "/tasks/"
                            }

                            +"Tasks"
                        }
                        button {
                            buttonCss()
                            onClick = {
                                window.location.href = "/admin/lessons/"
                            }

                            +"Lessons"
                        }
                    }

                    if (RoleDto.Role.ADMIN in roles) {
                        div {
                            button {
                                buttonCss()

                                +"Admin"

                                onClick = {
                                    displayAdminDropDown = displayAdminDropDown xor true
                                }
                            }
                            if (displayAdminDropDown) {
                                div {
                                    css {
                                        display = Display.flex
                                        flexDirection = FlexDirection.column
//                                position = Position.absolute
//                                top = 3.em
//                                right = 0.em
                                        backgroundColor = Color("#243b55")
                                    }

                                    button {
                                        buttonCss()
                                        onClick = {
                                            window.location.href = "/admin/users/"
                                        }

                                        +"Users"
                                    }
                                    button {
                                        buttonCss()
                                        onClick = {
                                            window.location.href = "/admin/groups/"
                                        }

                                        +"Groups"
                                    }
                                }
                            }
                        }
                    }
                    button {
                        buttonCss()
                        onClick = {
                            window.location.href = "/profile/"
                        }

                        +"Редактировать профиль"
                    }

                    if (RoleDto.Role.DEVELOPER in roles) {
                        div {
                            button {
                                buttonCss()

                                +"DevAdmin"

                                onClick = {
                                    displayAdminDropDown = displayAdminDropDown xor true
                                }
                            }

                            if (displayAdminDropDown) {
                                div {
                                    css {
                                        display = Display.flex
                                        flexDirection = FlexDirection.column
//                                position = Position.absolute
//                                top = 3.em
//                                right = 0.em
                                        backgroundColor = Color("#243b55")
                                    }

                                    button {
                                        +"Refresh tokens"

                                        onClick = {
                                            location.href = "/login/refreshTokens/"
                                        }
                                    }
                                    button {
                                        +"Switch admin"

                                        onClick = {
                                            MainScope().launch {
                                                Api.switchAdmin()
                                                location.href = "/login/refreshTokens/"
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (feedbackTabOn) {
                        button {
                            buttonCss()
                            onClick = {
                                window.location.href = "/feedback/"
                            }

                            +"Обратная связь"
                        }
                    }
                }

                button {
                    buttonCss()
                    +"Выйти из аккаунта"

                    onClick = {
                        userStore.dispatch(InvalidateAction)
                        location.reload()
                    }
                }
            }
        }

        Outlet {}
    }
