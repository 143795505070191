package client

import adm.groups.GroupsApi
import adm.groups.LessonToGroupDto
import adm.groups.StudentsGroupDto
import adm.lessons.LessonDto
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import org.w3c.dom.url.URLSearchParams
import react.*
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.main
import react.dom.html.ReactHTML.title
import react.dom.html.ReactHTML.ul
import react.router.dom.Link
import utils.borderCss
import web.location.location

class GroupInfoComponent(
    private var groupId: Long?,
) : Component<Props, State>() {
    override fun render(): ReactElement<Props> {
        return FC<Props> {
            var group: StudentsGroupDto? by useState(null)
            var lessons: List<LessonToGroupDto> by useState(emptyList())

            useEffectOnce {
                groupId = URLSearchParams(location.search).get("groupId")?.toLong()

                MainScope().launch {
                    val groupsFetchJob = async {
                        group = GroupsApi.fetchGroupById(groupId!!)
                    }
                    val lessonsFetchJob = async {
                        lessons = GroupsApi.getGroupLessons(groupId!!, LessonDto.State.PUBLISHED)
                    }

                    groupsFetchJob.await()
                    lessonsFetchJob.await()
                }
            }

            title {
                +"Информация о группе"
            }

            main {
                if (group == null) {
                    div {
                        +"Загрузка..."
                    }
                } else {
                    div {
                        h1 { +"Информация о группе:" }
                        div {
                            h2 {
                                +"Название:"
                            }
                            +group!!.name
                        }
                        div {
                            h2 {
                                +"Описание:"
                            }
                            +group!!.description
                        }
                        br {}
                        div {
                            borderCss()

                            h1 {
                                +"Уроки:"
                            }
                            ul {
                                lessons.sortedBy { it.orderNumber }.forEach { lesson ->
                                    li {
                                        Link {
                                            +"${lesson.orderNumber}. ${lesson.lesson!!.name}"
                                            if (lesson.lesson.type == LessonDto.Type.TEST) {
                                                +" (тест)"
                                            }

                                            to = when (lesson.lesson.type) {
                                                LessonDto.Type.REGULAR -> "/lesson?lessonId=${lesson.lesson.id}"
                                                LessonDto.Type.TEST -> "/lesson/test?lessonId=${lesson.lesson.id}"
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }.create()
    }
}
