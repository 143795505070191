package utils

import kotlinx.browser.document
import org.w3c.dom.Element
import react.use.useEventListener
import web.events.Event
import web.events.EventType

fun useActionAwayOverlay(
    elementId: String,
    ignoreElementIds: Collection<String> = emptyList(),
    callback: (Event) -> Unit,
) = useActionAwayOverlay(elementId, ignoreElementIds, callback, callback)

fun useActionAwayOverlay(
    elementId: String,
    ignoreElementIds: Collection<String> = emptyList(),
    callback: (Event) -> Unit,
    escapeButtonCallback: ((Event) -> Unit),
) {
    val handler = { event: Event ->
        val target = event.target
        val targetEl = target as? Element
        val deque = ArrayDeque<Element>().apply { targetEl?.let { add(it) } }
        var foundElement = false

        while (deque.isNotEmpty()) {
            val el = deque.removeFirst()
            if (el.id == elementId || el.id in ignoreElementIds) {
                foundElement = true
                break
            }
            el.parentElement?.let { deque.add(it) }
        }

        if (!foundElement) {
            callback(event)
        }
    }

    useEventListener(document.getElementById("root") as web.dom.Element, EventType("click"), handler = handler)
    useEventListener(document.getElementById("root") as web.dom.Element, EventType("keydown"), handler = {
        if (it.asDynamic().key == "Escape") {
            escapeButtonCallback(it)
        }
    })
}
