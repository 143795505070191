package auth

import kotlinx.browser.localStorage
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import org.w3c.dom.set
import redux.RAction
import redux.createStore
import redux.rEnhancer
import web.cssom.System
import kotlin.js.Date

@Serializable
data class LoginState(
    val state: State = State.UNAUTHENTICATED,
    val tokens: Tokens? = null,
    val updatedTs: Long? = null,
) {
    enum class State {
        AUTHENTICATED,
        UNAUTHENTICATED,
    }

    @Serializable
    data class Tokens(
        val accessToken: String,
        val refreshToken: String,
    )
}

class LoginAction(
    val accessToken: String,
    val refreshToken: String,
) : RAction

object InvalidateAction : RAction

val userStore = createStore(
    { state: LoginState, action: RAction ->
        val newState = when (action) {
            is LoginAction -> state.copy(
                state = LoginState.State.AUTHENTICATED,
                tokens = LoginState.Tokens(
                    accessToken = action.accessToken,
                    refreshToken = action.refreshToken,
                ),
                updatedTs = Date.now().toLong(),
            )

            is InvalidateAction -> LoginState()
            else -> state
        }
        localStorage["user"] = Json.encodeToString(newState)
        newState
    },
    localStorage["user"]?.let { Json.decodeFromString(it) } ?: LoginState(),
    rEnhancer(),
)

class UserDetailsAction(
    val user: UserDtoWithRoles,
) : RAction

val userDetailsStore = createStore(
    { state: UserDtoWithRoles?, action: RAction ->
        when (action) {
            is UserDetailsAction -> action.user
            else -> null
        }
    },
    null,
    rEnhancer(),
)