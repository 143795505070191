package adm.groups

import emotion.react.css
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.*
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.th
import react.dom.html.ReactHTML.tr
import utils.fallbackIfNotAdmin
import web.cssom.BorderCollapse
import web.cssom.px
import web.html.HTMLSelectElement
import web.location.location

val GroupsListComponent = FC<Props> {
    var groupsState: List<StudentsGroupDto> by useState(emptyList())
    var pageState: Int by useState(0)
    val stateRef = useRef<HTMLSelectElement>()

    useEffectOnce {
        MainScope().launch {
            fallbackIfNotAdmin()
        }
    }

    selectStudentsGroupState(stateRef)
    button {
        +"Search"
        onClick = {
            MainScope().launch {
                groupsState = GroupsApi.fetchGroups(studentsGroupStateRefToDto(stateRef), pageState)
            }
        }
    }
    button {
        +"Create"
        onClick = {
            location.href = "/admin/groups/add/"
        }
    }
    button {
        +"Clear default group"
        onClick = {
            MainScope().launch {
                GroupsApi.deleteDefault()
            }
        }
    }

    table {
        css {
            borderCollapse = BorderCollapse.separate
            borderSpacing = 50.px
        }

        tr {
            th {
                +"Id"
            }
            th {
                +"Name"
            }
            th {
                +"Type"
            }
            th {
                +"State"
            }
            th {
                +"Actions"
            }
        }

        groupsState.forEach { group ->
            tr {
                td {
                    +group.id.toString()
                }
                td {
                    +group.name
                }
                td {
                    +group.type.toString()
                }
                td {
                    +group.state.toString()
                }
                td {
                    button {
                        +"Edit"
                        onClick = {
                            location.href = "/admin/groups/edit/?id=${group.id}"
                        }
                    }
                }
            }
        }
    }

    div {
        button {
            +"Prev"
            onClick = {
                MainScope().launch {
                    if (pageState > 0) {
                        groupsState = GroupsApi.fetchGroups(studentsGroupStateRefToDto(stateRef), pageState - 1)
                        pageState--
                    }
                }
            }
        }
        +"Page: $pageState"
        button {
            +"Next"
            onClick = {
                pageState += 1
                MainScope().launch {
                    groupsState = GroupsApi.fetchGroups(studentsGroupStateRefToDto(stateRef), pageState + 1)
                    pageState++
                }
            }
        }
    }
}
