package tasks.edit

import adm.lessons.LessonDto
import adm.lessons.LessonsApi
import configuration.Conf
import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.joinAll
import kotlinx.coroutines.launch
import org.w3c.files.File
import react.*
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.details
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.main
import react.dom.html.ReactHTML.summary
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.tbody
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.textarea
import react.dom.html.ReactHTML.tr
import utils.fallbackIfNotTeacher
import web.cssom.Border
import web.cssom.Color
import web.cssom.LineStyle
import web.cssom.px
import web.dom.document
import web.html.*
import web.location.location

@JsModule("easymde")
@JsNonModule
external class EasyMDE(options: dynamic) {
    fun value(): String
    fun value(value: String)
}

data class TasksEditComponentState(
    val isNew: Boolean = true,
    val id: Long? = null,
    val title: String? = null,
    val body: String? = null,
    var answer: String? = null,
    val type: KnowledgeDto.Type? = null,
    var state: KnowledgeDto.State? = null,
    var difficulty: KnowledgeDto.Difficulty? = null,
    var taskNumber: Int? = null,
    var attachments: List<AttachmentDto> = emptyList(),
)

class TasksEditComponent(
    private val taskId: Long? = null,
) : Component<Props, State>() {
    private suspend fun uploadAttachment(file: File, id: Long): Long {
        console.log(file)
        val result = try {
            sendFile(file, id)
        } catch (e: Exception) {
            console.log(e)
            auth.Api.refresh()
            sendFile(file, id)
            throw e
        }
        return result
    }

    suspend fun save(
        tasksEditComponentState: TasksEditComponentState,
    ) {
        val dto = try {
            KnowledgeDto(
                id = tasksEditComponentState.id,
                title = tasksEditComponentState.title!!,
                body = tasksEditComponentState.body!!,
                answer = tasksEditComponentState.answer,
                type = tasksEditComponentState.type!!,
                state = tasksEditComponentState.state!!,
                difficulty = tasksEditComponentState.difficulty!!,
                taskNumber = tasksEditComponentState.taskNumber!!,
                createdAt = 0,
                createdBy = 0,
                updatedAt = 0,
                updatedBy = 0,
            )
        } catch (e: Exception) {
            window.alert("Please fill all fields. They should not be empty or Any.")
            console.log(e)
            throw e
        }

        try {
            if (dto.id == null) {
                val id = createKnowledge(dto)
                location.href = window.location.toString() + "?id=$id"
            } else {
                updateKnowledge(dto)
            }
        } catch (e: Exception) {
            console.log(e)
            auth.Api.refresh()
            if (dto.id == null) {
                val id = createKnowledge(dto)
                window.history.pushState(null, "", window.location.toString() + "?id=$id")
            } else {
                updateKnowledge(dto)
            }
        }
    }

    private suspend fun fetchState(): TasksEditComponentState {
        console.log("fetchState")
        val knowledgeDto = fetchKnowledge(taskId!!)
        val newState = TasksEditComponentState(
            isNew = false,
            id = knowledgeDto.id,
            body = knowledgeDto.body,
            answer = knowledgeDto.answer,
            type = knowledgeDto.type,
            state = knowledgeDto.state,
            difficulty = knowledgeDto.difficulty,
            taskNumber = knowledgeDto.taskNumber,
            title = knowledgeDto.title,
            attachments = getAttachments(taskId),
        )
        console.log(newState)
        return newState
    }

    private fun PropsWithClassName.borderCss() {
        css {
            border = Border(1.px, LineStyle.solid, Color("#000000"))
        }
    }

    override fun render(): ReactNode {
        return FC<Props> {
            var localState: TasksEditComponentState? by useState(null)
            val answerRef = useRef<HTMLTextAreaElement>()
            val difficultyRef = useRef<HTMLSelectElement>()
            val taskNumberRef = useRef<HTMLInputElement>()
            val typeRef = useRef<HTMLSelectElement>()
            val localStateRef = useRef<HTMLSelectElement>()
            var hiddenAnswer: Boolean by useState(true)
            val titleRef = useRef<HTMLInputElement>()
            val newAttachmentRef = useRef<HTMLFormElement>()
            var mde: EasyMDE? by useState(null)
            var lessons: List<LessonDto>? by useState(null)

            useEffectOnce {
                MainScope().launch {
                    val jobs = mutableListOf(
                        launch {
                            fallbackIfNotTeacher()
                        },
                    )

                    var currState: TasksEditComponentState? = localState
                    if (taskId != null && localState == null) {
                        jobs.add(
                            launch {
                                val newState = fetchState()
                                localState = newState
                                currState = newState
                                hiddenAnswer =
                                    newState.type.toString() !in listOf("TASK_VISIBLE_ANSWER", "TASK_HIDDEN_ANSWER")
                                document.getElementById("my-text-area")!!.innerText = newState.body!!
                            },
                        )
                    }
                    if (taskId != null) {
                        jobs.add(
                            launch {
                                lessons = LessonsApi.fetchLessonsByKnowledge(taskId)
                            },
                        )
                    }

                    jobs.joinAll()

                    mde = EasyMDE(
                        js("{element: document.getElementById('my-text-area'), lineWrapping: false}"),
                    )
                    currState?.body?.let { mde?.value(it.replace("\n", "&#13;")) }
                }
            }

            fun ChildrenBuilder.attachmentDiv(
                newAttachmentRef: MutableRefObject<HTMLFormElement>,
                titleRef: MutableRefObject<HTMLInputElement>,
                answerRef: MutableRefObject<HTMLTextAreaElement>,
                typeRef: MutableRefObject<HTMLSelectElement>,
                localStateRef: MutableRefObject<HTMLSelectElement>,
                difficultyRef: MutableRefObject<HTMLSelectElement>,
                taskNumberRef: MutableRefObject<HTMLInputElement>,
            ) {
                if (localState?.isNew != true) {
                    div {
                        borderCss()
                        div {
                            id = "attachements"
                            +"Attachements: "

                            table {
                                tbody {
                                    localState?.attachments?.forEach { attachment ->
                                        tr {
                                            td {
                                                +attachment.name
                                            }
                                            td {
                                                button {
                                                    +"Copy link"

                                                    onClick = {
                                                        val link =
                                                            "${Conf.globalUrlBase}/v0/knowledge/${localState?.id}/attachment/${attachment.hash}/load"
                                                        window.navigator.clipboard.writeText(link)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        div {
                            id = "attachements-add"

                            +"Add new attachment"

                            form {
                                input {
                                    type = InputType.Companion.file
                                }

                                ref = newAttachmentRef
                            }

                            button {
                                +"Upload"

                                onClick = {
                                    saveFromRef(
                                        mde,
                                        localState,
                                        titleRef,
                                        answerRef,
                                        typeRef,
                                        localStateRef,
                                        difficultyRef,
                                        taskNumberRef,
                                    )

                                    MainScope().launch {
                                        val attachmentId = uploadAttachment(
                                            (newAttachmentRef.current!!.firstChild!! as HTMLInputElement).files!![0] as File,
                                            localState?.id!!,
                                        )

                                        val newAttachments = getAttachment(localState?.id!!, attachmentId)

                                        localState = localState?.copy(
                                            attachments = (localState?.attachments ?: emptyList()) + newAttachments,
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }

            main {
                div {
                    +"Id: ${localState?.id}"
                }

                textarea {
                    id = "my-text-area"
                }

                if (taskId == null || localState != null) {
                    titleInput(titleRef, localState)

                    selectTaskNumber(taskNumberRef, localState?.taskNumber)
                    selectDifficulty(difficultyRef, localState?.difficulty?.toString())

                    selectType(typeRef, localState?.type?.toString()) {
                        hiddenAnswer = it.target.value !in listOf("TASK_VISIBLE_ANSWER", "TASK_HIDDEN_ANSWER")
                    }
                    answerDiv(hiddenAnswer, answerRef, localState)

                    selectState(localStateRef, localState?.state?.toString())

                    attachmentDiv(
                        newAttachmentRef,
                        titleRef,
                        answerRef,
                        typeRef,
                        localStateRef,
                        difficultyRef,
                        taskNumberRef,
                    )

                    button {
                        onClick = {
                            saveFromRef(
                                mde,
                                localState,
                                titleRef,
                                answerRef,
                                typeRef,
                                localStateRef,
                                difficultyRef,
                                taskNumberRef,
                            )
                        }

                        +"Save"
                    }

                    lessonsDetails(lessons)
                }
            }
        }.create()
    }

    private fun ChildrenBuilder.lessonsDetails(
        lessons: List<LessonDto>?,
    ) {
        lessons?.let {
            details {
                borderCss()

                summary {
                    +"Lessons with this task"
                }

                table {
                    tbody {
                        lessons.forEach { lesson ->
                            tr {
                                td {
                                    +lesson.name
                                }
                                td {
                                    button {
                                        +"Open"
                                        onClick = {
                                            window.open("/admin/lessons/edit?id=${lesson.id}")
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private fun saveFromRef(
        mde: EasyMDE?,
        localState: TasksEditComponentState?,
        titleRef: MutableRefObject<HTMLInputElement>,
        answerRef: MutableRefObject<HTMLTextAreaElement>,
        typeRef: MutableRefObject<HTMLSelectElement>,
        localStateRef: MutableRefObject<HTMLSelectElement>,
        difficultyRef: MutableRefObject<HTMLSelectElement>,
        taskNumberRef: MutableRefObject<HTMLInputElement>,
    ) {
        var localState1 = localState
        MainScope().launch {
            console.log(mde)
            val newState = TasksEditComponentState(
                isNew = localState1?.isNew ?: true,
                id = localState1?.id,
                title = titleRef.current?.value,
                body = mde!!.value(),
                answer = answerRef.current?.value,
                type = KnowledgeDto.Type.valueOf(typeRef.current?.value ?: "TASK_VISIBLE_ANSWER"),
                state = KnowledgeDto.State.valueOf(localStateRef.current?.value ?: "DRAFT"),
                difficulty = KnowledgeDto.Difficulty.valueOf(difficultyRef.current?.value ?: "EASY"),
                taskNumber = taskNumberRef.current?.value?.toInt(),
            )

            save(newState)
            localState1 = newState.copy(isNew = false)
        }
    }

    private fun ChildrenBuilder.titleInput(
        answerRef: MutableRefObject<HTMLInputElement>,
        localState: TasksEditComponentState?,
    ) {
        div {
            id = "title"
            +"Title: "
            input {
                ref = answerRef

                defaultValue = localState?.title
            }
        }
    }

    private fun ChildrenBuilder.answerDiv(
        hiddenAnswer: Boolean,
        answerRef: MutableRefObject<HTMLTextAreaElement>,
        localState: TasksEditComponentState?,
    ) {
        div {
            id = "answer"
            hidden = hiddenAnswer
            +"Answer: "
            textarea {
                id = "my-text-area-answer"
                ref = answerRef

                defaultValue = localState?.answer
            }
        }
    }
}
