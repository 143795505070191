package auth

import configuration.Conf
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.coroutines.delay
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.w3c.fetch.Headers
import org.w3c.fetch.RequestInit
import org.w3c.fetch.Response

@Serializable
data class UserContactDetailsDto(
    val id: Long?,
    val userId: Long,
    val type: ContactDetailsType,
    val value: String,
) {
    enum class ContactDetailsType(val humanReadableName: String) {
        EMAIL("Электронная почта"),
        PHONE("Номер телефона"),
        TELEGRAM("Телеграм"),
    }
}

@Serializable
data class UserDto(
    val id: Long? = null,
    val username: String,
)

@Serializable
data class UserWithPassDto(
    val id: Long? = null,
    val username: String,
    val password: String?,
)

@Serializable
data class UserDtoWithRoles(
    val id: Long,
    val username: String,
    val roles: List<RoleDto>,
)

@Serializable
data class JwtTokensDto(
    val access: String?,
    val refresh: String?,
)

@Serializable
data class RoleDto(
    val role: Role,
) {
    @Serializable
    enum class Role {
        ADMIN,
        USER,
        TEACHER,
        CURATOR,
        DEVELOPER,
    }
}

suspend fun authRetryFetchWrapper(
    url: String,
    init: RequestInit = RequestInit(
        headers = Headers(),
    ),
    retryCount: Int = 2,
): Response {
    var response: Response
    var retry = 0
    var tsState = userStore.getState().updatedTs

    if (init.headers == null) init.headers = Headers()

    while (true) {
        init.headers.set("Authorization", Api.getAuthHeader())

        response = window.fetch(url, init).await()
        if (response.status == 401.toShort() || response.status == 403.toShort()) {
            if (retry >= retryCount) {
                throw IllegalStateException("Refresh failed")
            }
            if (tsState == userStore.getState().updatedTs) {
                try {
                    Api.refresh()
                } catch (_: IllegalStateException) {
                    delay(100)
                }
            }

            tsState = userStore.getState().updatedTs
            retry++
        } else {
            break
        }
    }
    return response
}

object Api {
    fun getAuthHeader() = "Bearer ${storageTokens.accessToken}"

    private val storageTokens: LoginState.Tokens
        get() = userStore.getState().tokens ?: throw IllegalStateException("No tokens")

    suspend fun auth(login: String, password: String): JwtTokensDto {
        val response = window
            .fetch(
                "${Conf.globalUrlBase}/v0/users/auth",
                object : RequestInit {
                    override var method: String? = "POST"
                    override var headers: dynamic = Headers().apply {
                        set("Content-Type", "application/json")
                    }
                    override var body: dynamic =
                        Json.encodeToString(UserWithPassDto(username = login, password = password))
                },
            )
            .await()
            .text()
            .await()

        val result = Json.decodeFromString<JwtTokensDto>(response)

        userStore.dispatch(LoginAction(result.access ?: "", result.refresh ?: ""))

        return result
    }

    suspend fun refresh(): JwtTokensDto {
        val tokens = storageTokens

        val response = window
            .fetch(
                "${Conf.globalUrlBase}/v0/users/refresh",
                object : RequestInit {
                    override var method: String? = "POST"
                    override var headers: dynamic = Headers().apply {
                        set("Authorization", getAuthHeader())
                    }
                    override var body: dynamic = tokens.refreshToken
                },
            )
            .await()

        if (!response.ok) {
            throw IllegalStateException("Refresh failed")
        }

        val result = Json.decodeFromString<JwtTokensDto>(
            response.text()
                .await(),
        )

        userStore.dispatch(LoginAction(result.access ?: "", result.refresh ?: ""))

        return result
    }

    suspend fun editMe(userWithPassDto: UserWithPassDto) {
        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/users/me",
            RequestInit(
                method = "PUT",
                headers = Headers().apply {
                    set("Content-Type", "application/json")
                },
                body = Json.encodeToString(userWithPassDto),
            ),
        )
            .text()
            .await()

        if (response != "OK") {
            throw IllegalStateException("Edit failed")
        }
    }

    suspend fun me(): UserDtoWithRoles {
        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/users/me",
            RequestInit(
                headers = Headers().apply {
                    set("Content-Type", "application/json")
                },
            ),
        )

        return Json.decodeFromString(response.text().await())
    }

    suspend fun switchAdmin() {
        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/users/switch_admin_role",
            RequestInit(
                method = "PATCH",
                headers = Headers().apply {
                    set("Content-Type", "application/json")
                },
            ),
        )
            .text()
            .await()
    }
}
