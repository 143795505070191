package curator

import js.core.jso
import react.create
import react.router.RouteObject

val curatorRoutes = jso<RouteObject> {
    path = "curator/"
    children = arrayOf(
        jso {
            path = "progress/"
            children = arrayOf(
                jso {
                    index = true
                    path = ""
                    element = CuratorGroupsPage.create()
                },
            )
        },
    )
}