import adm.adminRoutes
import adm.users.UserEditComponent
import auth.LoginComponent
import auth.RegisterComponent
import auth.RunRefreshTokensComponent
import client.clientRoutes
import curator.curatorRoutes
import feedback.feedbackRoutes
import js.core.jso
import kotlinx.browser.document
import react.FC
import react.create
import react.dom.client.createRoot
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.title
import react.router.RouterProvider
import react.router.dom.createBrowserRouter
import tasks.edit.tasksRoutes

fun main() {
    val document = document.getElementById("root")!!
    val appRouter = createBrowserRouter(
        arrayOf(
            jso {
                path = "/"
                element = NavigationComponent.create()
                children = arrayOf(
                    // main page
                    jso {
                        index = true
                        element = FC {
                            title {
                                +"Главная страница"
                            }

                            h2 {
                                +"Выберете тему."
                            }
                        }.create()
                    }, // main page

                    // login
                    jso {
                        path = "/login/"
                        children = arrayOf(
                            jso {
                                index = true
                                path = ""
                                element = LoginComponent.create()
                            },
                            jso {
                                path = "refreshTokens/"
                                element = RunRefreshTokensComponent.create()
                            },
                        )
                    }, // login

                    jso {
                        path = "/register/"
                        element = RegisterComponent.create()
                    }, // register

                    // profile,
                    jso {
                        path = "/profile/"
                        index = true
                        element = UserEditComponent(null, true).render()
                    }, // profile

                    // tasks
                    tasksRoutes,

                    adminRoutes,

                    clientRoutes,

                    curatorRoutes,

                    feedbackRoutes,
                )
            },
        ),
    )

    createRoot(document as web.dom.Element).render(
        FC {
            RouterProvider { router = appRouter }
        }.create(),
    )
}
