package adm.lessons

import auth.authRetryFetchWrapper
import configuration.Conf
import kotlinx.coroutines.await
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import org.w3c.dom.url.URLSearchParams
import org.w3c.fetch.Headers
import org.w3c.fetch.RequestInit
import tasks.edit.KnowledgeDto

@Serializable
data class LessonDto(
    val id: Long?,
    val name: String,
    val publishAt: Long,
    val taskNumber: Int,
    val state: State,
    val type: Type = Type.REGULAR,
    val payload: LessonPayloadDto = LessonPayloadDto.LessonPayloadStub,
) {
    enum class State {
        DRAFT,
        READY_TO_PUBLISH,
        PUBLISHED,
    }

    enum class Type {
        REGULAR,
        TEST,
    }
}

@Serializable
sealed interface LessonPayloadDto {
    @Serializable
    @SerialName("STUB")
    object LessonPayloadStub : LessonPayloadDto
}

@Serializable
@SerialName("TEST")
data class TestLessonPayloadDto(
    val timeToTest: Long,
) : LessonPayloadDto

@Serializable
data class LessonToKnowledgeInfoDto(
    val lessonId: Long,
    val knowledgeId: Long,
    val orderNumber: Int,
)

@Serializable
data class LessonToKnowledgeInfoWithKnowledgeBodyDto(
    val lessonId: Long,
    val knowledge: KnowledgeDto,
    val orderNumber: Int,
)

@Serializable
data class StudentLessonKnowledgeStatusDto(
    val userId: Long,
    val lessonId: Long,
    val knowledgeId: Long,
    val status: Status,
    val attempts: Int,
    val updatedAt: Long,
) {
    enum class Status {
        NOT_STARTED,
        IN_PROGRESS,
        COMPLETED,
    }
}

@Serializable
data class TestLessonUserStatusDto(
    val id: Long?,
    val lessonId: Long,
    val userId: Long,
    val createdAt: Long,
    val status: Status,
) {
    enum class Status {
        NOT_STARTED,
        STARTED,
        FINISHED,
    }

    companion object {
        fun sutb() = TestLessonUserStatusDto(
            id = null,
            lessonId = 0,
            userId = 0,
            createdAt = 0,
            status = Status.NOT_STARTED,
        )
    }
}

@Serializable
data class TestLessonAnswerAttemptDto(
    val id: Long?,
    val lessonId: Long,
    val knowledgeId: Long,
    val userId: Long,
    val createdAt: Long,
    val answer: String,
)

@Serializable
data class TestLessonResultDto(
    val correctCount: Int,
    val totalCount: Int,
)

object LessonsApi {
    suspend fun validateAnswer(lessonId: Long, knowledgeId: Long, answer: String): StudentLessonKnowledgeStatusDto {
        val search = URLSearchParams()
        search.append("answer", answer)

        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/lessons/$lessonId/studentKnowledgeStateMy/$knowledgeId/validateAnswer?$search",
            RequestInit(
                method = "POST",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
            ),
        )

        return Conf.json.decodeFromString(response.text().await())
    }

    suspend fun incrementMyLessonKnowledgeStatus(
        lessonId: Long,
        knowledgeId: Long,
        status: StudentLessonKnowledgeStatusDto.Status,
    ): StudentLessonKnowledgeStatusDto {
        val search = URLSearchParams()
        search.append("status", status.name)

        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/lessons/$lessonId/studentKnowledgeStateMy/$knowledgeId?$search",
            RequestInit(
                method = "POST",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
            ),
        )

        return Conf.json.decodeFromString(response.text().await())
    }

    suspend fun searchLessons(page: Int, state: LessonDto.State?, type: LessonDto.Type?): List<LessonDto> {
        val searchParams = URLSearchParams()
        searchParams.append("page", page.toString())
        state?.let { searchParams.append("state", it.name) }
        type?.let { searchParams.append("type", it.name) }

        val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/lessons/?$searchParams")
            .text()
            .await()

        return Conf.json.decodeFromString(response)
    }

    suspend fun createLesson(lessonDto: LessonDto): LessonDto {
        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/lessons/",
            RequestInit(
                method = "POST",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
                body = Conf.json.encodeToString(lessonDto),
            ),
        )
            .text()
            .await()

        return Conf.json.decodeFromString(response)
    }

    suspend fun updateLesson(lessonDto: LessonDto) {
        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/lessons/${lessonDto.id}",
            RequestInit(
                method = "PUT",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
                body = Conf.json.encodeToString(lessonDto),
            ),
        )
            .text()
            .await()
    }

    suspend fun fetchLesson(lessonId: Long): LessonDto {
        val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/lessons/$lessonId")
            .text()
            .await()

        return Conf.json.decodeFromString(response)
    }

    suspend fun fetchLessonToKnowledgeInfos(
        lessonId: Long,
        showAnswer: Boolean = false,
    ): List<LessonToKnowledgeInfoWithKnowledgeBodyDto> {
        val search = URLSearchParams()
        search.append("showAnswer", showAnswer.toString())

        val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/lessons/$lessonId/knowledge?$search")
            .text()
            .await()

        return Conf.json.decodeFromString(response)
    }

    suspend fun updateLessonToKnowledgeInfos(
        lessonId: Long,
        lessonToKnowledgeInfos: List<LessonToKnowledgeInfoDto>,
    ) {
        authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/lessons/$lessonId/knowledge",
            RequestInit(
                method = "POST",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
                body = Conf.json.encodeToString(lessonToKnowledgeInfos),
            ),
        )
            .status
            .let {
                require(it == 200.toShort()) { "Update lesson to knowledge info failed" }
            }
    }

    suspend fun fetchMyLessonKnowledgeStatus(lessonId: Long): List<StudentLessonKnowledgeStatusDto> {
        val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/lessons/$lessonId/studentKnowledgeStateMy")
            .text()
            .await()

        return Conf.json.decodeFromString(response)
    }

    suspend fun schedulePublishLesson(lessonId: Long, publishAt: Long) {
        authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/lessons/$lessonId/schedulePublish?publishAt=$publishAt",
            RequestInit(
                method = "POST",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
            ),
        )
            .status
            .let {
                require(it == 200.toShort()) { "Schedule publish lesson failed" }
            }
    }

    suspend fun fetchLessonsByKnowledge(knowledgeId: Long): List<LessonDto> {
        val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/lessons/byKnowledge/$knowledgeId")
            .text()
            .await()

        return Conf.json.decodeFromString(response)
    }

    suspend fun fetchTestLessonStatus(lessonId: Long): TestLessonUserStatusDto {
        val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/lessons/$lessonId/testLesson/status")
            .text()
            .await()

        return Conf.json.decodeFromString(response)
    }

    suspend fun startTestLesson(lessonId: Long): TestLessonUserStatusDto {
        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/lessons/$lessonId/testLesson/start",
            RequestInit(
                method = "POST",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
            ),
        )
            .text()
            .await()

        return Conf.json.decodeFromString(response)
    }

    suspend fun answerToKnowledeInTestLesson(
        lessonId: Long,
        knowledgeId: Long,
        answer: String,
    ): TestLessonAnswerAttemptDto {
        val urlSearchParams = URLSearchParams()
        urlSearchParams.append("answer", answer)

        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/lessons/$lessonId/testLesson/$knowledgeId/answer?$urlSearchParams",
            RequestInit(
                method = "POST",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
            ),
        )
            .text()
            .await()

        return Conf.json.decodeFromString(response)
    }

    suspend fun fetchTestLessonAnswerMyAttempts(lessonId: Long): List<TestLessonAnswerAttemptDto> {
        val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/lessons/$lessonId/testLesson/myProgress")
            .text()
            .await()

        return Conf.json.decodeFromString(response)
    }

    suspend fun finishTestLesson(lessonId: Long): TestLessonUserStatusDto {
        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/lessons/$lessonId/testLesson/finish",
            RequestInit(
                method = "POST",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
            ),
        )
            .text()
            .await()

        return Conf.json.decodeFromString(response)
    }

    suspend fun fetchTestLessonResult(lessonId: Long): TestLessonResultDto {
        val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/lessons/$lessonId/testLesson/result")
            .text()
            .await()

        return Conf.json.decodeFromString(response)
    }
}
