package adm.users

import adm.users.UserAdminApi.getAllUsers
import auth.RoleDto
import auth.UserDtoWithRoles
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.*
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.router.dom.Link
import utils.fallbackIfNotAdmin
import utils.fallbackIfNotTeacher
import web.html.HTMLSelectElement
import web.location.location

val UsersListComponent = FC<Props> {
    userListComponentBase<ChildrenBuilder>(it) { user ->
        return@userListComponentBase {
            Link {
                to = "/admin/users/edit/?id=${user.id}"
                +"Edit"
            }
        }
    }
}

fun <P : ChildrenBuilder> ChildrenBuilder.userListComponentBase(
    props: Props,
    securityFallback: suspend () -> Unit = { fallbackIfNotAdmin() },
    actionsColumn: (UserDtoWithRoles) -> (@ReactDsl P.() -> Unit),
) {
    var users: List<UserDtoWithRoles> by useState(emptyList())
    var pageState: Int by useState(0)
    val roleRef = useRef<HTMLSelectElement>()
    val mainScope = MainScope()

    useEffectOnce {
        mainScope.launch {
            securityFallback()
        }
    }

    div {
        roleSelector(roleRef)

        button {
            onClick = { mouseEvent ->
                mainScope.launch {
                    users = fetchUsers(userRoleRefToEnum(roleRef), pageState)
                }
            }

            +"Load"
        }

        button {
            onClick = {
                location.href = "/admin/users/add/"
            }

            +"Create"
        }
    }

    div {
        ReactHTML.table {
            ReactHTML.tr {
                ReactHTML.th {
                    +"Id"
                }
                ReactHTML.th {
                    +"Username"
                }
                ReactHTML.th {
                    +"Roles"
                }
                ReactHTML.th {
                    +"Actions"
                }
            }
            users.forEach { user ->
                ReactHTML.tr {
                    ReactHTML.td {
                        +user.id.toString()
                    }
                    ReactHTML.td {
                        +user.username
                    }
                    ReactHTML.td {
                        ReactHTML.ul {
                            user.roles.forEach { role ->
                                ReactHTML.li {
                                    +role.role.name
                                }
                            }
                        }
                    }
                    ReactHTML.td(actionsColumn(user))
                }
            }
        }
    }

    div {
        button {
            onClick = {
                val curPage = pageState
                if (curPage == 0) {
                    window.alert("Already on first page")
                } else {
                    pageState--
                    mainScope.launch {
                        users = fetchUsers(userRoleRefToEnum(roleRef), curPage - 1)
                    }
                }
            }

            +"Prev"
        }

        +pageState.toString()

        button {
            onClick = {
                val curPage = pageState
                pageState++
                mainScope.launch {
                    users = fetchUsers(userRoleRefToEnum(roleRef), curPage + 1)
                }
            }

            +"Next"
        }
    }
}

suspend fun fetchUsers(role: RoleDto.Role?, page: Int = 0): List<UserDtoWithRoles> {
    return getAllUsers(role, page)
}
