package utils

import auth.RoleDto
import auth.userDetailsStore
import kotlinx.coroutines.delay
import web.location.location

suspend fun fallBackIfHasNoRole(href: String, roles: Collection<RoleDto.Role>) {
    var user = userDetailsStore.getState()

    while (user == null) {
        delay(100)
        user = userDetailsStore.getState()
    }

    if (user.roles.map { it.role }.none { it in roles }) {
        location.href = href
    }
}

suspend fun fallbackIfNotAdmin() = fallBackIfHasNoRole("/", listOf(RoleDto.Role.ADMIN))

suspend fun fallbackIfNotCurator() = fallBackIfHasNoRole("/", listOf(RoleDto.Role.CURATOR, RoleDto.Role.ADMIN))

suspend fun fallbackIfNotTeacher() = fallBackIfHasNoRole("/", listOf(RoleDto.Role.TEACHER, RoleDto.Role.ADMIN))
