package auth

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.dom.html.ReactHTML.button
import react.useEffectOnce
import react.useState
import web.location.location

val RunRefreshTokensComponent = FC<Props> {
    var isTried: Boolean by useState(false)

    useEffectOnce {
        MainScope().launch {
            try {
                Api.refresh()
                location.href = "/"
            } catch (e: Exception) {
                isTried = true
            }
        }
    }

    if (!isTried) {
        +"Loading..."
    } else {
        +"Failed to refresh tokens"

        button {
            +"Login"
            onClick = {
                location.href = "/login"
            }
        }
    }
}
