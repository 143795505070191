package adm.lessons

import react.ChildrenBuilder
import react.MutableRefObject
import react.Ref
import react.dom.events.ChangeEventHandler
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import web.html.HTMLInputElement
import web.html.HTMLSelectElement
import web.html.InputType

fun ChildrenBuilder.stateSelect(
    selectRef: Ref<HTMLSelectElement>? = null,
    includeAny: Boolean = true,
    defaultState: LessonDto.State? = null,
) {
    select {
        ref = selectRef

        if (includeAny) {
            option {
                value = null
                +"Any"
            }
        }
        option {
            value = LessonDto.State.DRAFT.name
            +"Draft"
        }
        option {
            value = LessonDto.State.READY_TO_PUBLISH.name
            +"Ready to publish"
        }
        option {
            value = LessonDto.State.PUBLISHED.name
            +"Published"
        }

        defaultValue = defaultState?.name
    }
}

fun ChildrenBuilder.typeSelect(
    selectRef: Ref<HTMLSelectElement>? = null,
    includeAny: Boolean = true,
    defaultType: LessonDto.Type? = null,
    onChangeFun: ChangeEventHandler<HTMLSelectElement>? = null,
) {
    select {
        ref = selectRef

        if (includeAny) {
            option {
                value = null
                +"Any"
            }
        }
        option {
            value = LessonDto.Type.REGULAR.name
            +"Regular"
        }
        option {
            value = LessonDto.Type.TEST.name
            +"Test"
        }

        defaultValue = defaultType?.name

        onChange = onChangeFun
    }
}

fun ChildrenBuilder.timeToTestInput(
    inputRef: Ref<HTMLInputElement>? = null,
    default: Long? = null,
) {
    input {
        type = InputType.number
        ref = inputRef
        defaultValue = default?.toString()
    }
}

fun lessonStateRefToEnum(selectRef: MutableRefObject<HTMLSelectElement>): LessonDto.State? {
    return try {
        selectRef.current?.value?.let { LessonDto.State.valueOf(it) }
    } catch (e: IllegalStateException) {
        null
    }
}

fun lessonTypeRefToEnum(selectRef: MutableRefObject<HTMLSelectElement>): LessonDto.Type? {
    return try {
        selectRef.current?.value?.let { LessonDto.Type.valueOf(it) }
    } catch (e: IllegalStateException) {
        null
    }
}
