package curator

import adm.groups.LessonToGroupDto
import adm.groups.StudentsGroupDto
import adm.lessons.LessonDto
import adm.lessons.StudentLessonKnowledgeStatusDto
import adm.lessons.TestLessonAnswerAttemptDto
import adm.lessons.TestLessonUserStatusDto
import auth.UserDto
import auth.authRetryFetchWrapper
import configuration.Conf
import kotlinx.coroutines.await
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json

@Serializable
data class StudentStatusesDto(
    val student: UserDto,
    val statuses: List<StudentLessonKnowledgeStatusDto>,
)

@Serializable
data class LessonProgressDto(
    val lesson: LessonDto,
    val studentsProgress: List<StudentStatusesDto>,
)

@Serializable
data class GroupAndLessonsDto(
    val group: StudentsGroupDto,
    val lessons: List<LessonToGroupDto>,
)

@Serializable
data class GroupUserTestAttemptDto(
    val user: UserDto,
    val status: TestLessonUserStatusDto,
    val attempts: List<TestLessonAnswerAttemptDto>,
)

@Serializable
data class GroupTestAttemptsDto(
    val lesson: LessonDto,
    val studentsAttempts: List<GroupUserTestAttemptDto>,
)


object CuratorApi {
    suspend fun getGroups(): List<StudentsGroupDto> {
        val response = authRetryFetchWrapper(Conf.globalUrlBase + "/v0/curator/groups")
        return Conf.json.decodeFromString(response.text().await())
    }

    suspend fun getLessons(groupId: Long): List<LessonToGroupDto> {
        val response = authRetryFetchWrapper(Conf.globalUrlBase + "/v0/curator/groups/$groupId/lessons")
        return Conf.json.decodeFromString(response.text().await())
    }

    suspend fun getLessonProgress(groupId: Long, lessonId: Long): LessonProgressDto {
        val response =
            authRetryFetchWrapper(Conf.globalUrlBase + "/v0/curator/groups/$groupId/lessons/$lessonId/progress")
        return Conf.json.decodeFromString(response.text().await())
    }

    suspend fun getGroupWithLessons(): List<GroupAndLessonsDto> {
        val response = authRetryFetchWrapper(Conf.globalUrlBase + "/v0/curator/groupsWithLessons")
        val text = response.text().await()
        try {
            return Conf.json.decodeFromString(text)
        } catch (e: Exception) {
            console.log(e.stackTraceToString())
            throw e
        }
    }

    suspend fun fetchTestLessonProgress(groupId: Long, lessonId: Long): GroupTestAttemptsDto {
        val response =
            authRetryFetchWrapper(Conf.globalUrlBase + "/v0/curator/groups/$groupId/lessons/$lessonId/testProgress")
        return Conf.json.decodeFromString(response.text().await())
    }
}
