package adm.groups

import adm.lessons.LessonDto
import auth.Api
import auth.UserDto
import auth.authRetryFetchWrapper
import configuration.Conf
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import org.w3c.dom.url.URLSearchParams
import org.w3c.fetch.Headers
import org.w3c.fetch.RequestInit

@Serializable
sealed interface IUserToGroupConnectionInfoDto {
    val userId: Long
    val groupId: Long
    val paymentAt: Long
    val paymentType: PaymentType
    val paymentState: PaymentState
    val hasAccess: Boolean
    val paymentExpiresAt: Long

    enum class PaymentType {
        FREE,
        PAID,
    }

    enum class PaymentState {
        PAID,
        NOT_PAID,
    }
}

@Serializable
data class StudentsGroupDto(
    val id: Long?,
    val name: String,
    val description: String,
    val type: StudentsGroupTypeDto,
    val state: StudentsGroupStateDto,
)

@Serializable
enum class StudentsGroupStateDto {
    ACTIVE,
    INACTIVE,
    ARCHIVED,
}

@Serializable
enum class StudentsGroupTypeDto {
    COURSE,
}

@Serializable
data class StudentsGroupWithStudentsAndCuratorsDto(
    val id: Long?,
    val name: String,
    val description: String,
    val type: StudentsGroupTypeDto,
    val state: StudentsGroupStateDto,

    val students: List<UserToGroupConnectionInfoWithUserDto>,
    val curators: List<UserDto>,
)

@Serializable
data class UserToGroupConnectionInfoDto(
    override val userId: Long,
    override val groupId: Long,
    override val paymentAt: Long,
    override val paymentType: IUserToGroupConnectionInfoDto.PaymentType,
    override val paymentState: IUserToGroupConnectionInfoDto.PaymentState,
    override val hasAccess: Boolean,
    override val paymentExpiresAt: Long,
) : IUserToGroupConnectionInfoDto

@Serializable
data class UserToGroupConnectionInfoWithUserDto(
    override val userId: Long,
    override val groupId: Long,
    override val paymentAt: Long,
    override val paymentType: IUserToGroupConnectionInfoDto.PaymentType,
    override val paymentState: IUserToGroupConnectionInfoDto.PaymentState,
    override val hasAccess: Boolean,
    override val paymentExpiresAt: Long,

    val user: UserDto,
) : IUserToGroupConnectionInfoDto

@Serializable
data class LessonToGroupDto(
    val lessonId: Long?,
    val groupId: Long?,
    val orderNumber: Int,
    val lesson: LessonDto? = null,
)

object GroupsApi {
    suspend fun fetchMyGroups(): List<StudentsGroupDto> {
        val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/groups/my")
            .text()
            .await()

        return Conf.json.decodeFromString(response)
    }

    suspend fun fetchGroups(
        studentsGroupStateRefToDto: StudentsGroupStateDto?,
        pageState: Int,
    ): List<StudentsGroupDto> {
        val searchParams = URLSearchParams().apply {
            append("page", pageState.toString())
            studentsGroupStateRefToDto?.let { append("state", it.name) }
        }

        return authRetryFetchWrapper("${Conf.globalUrlBase}/v0/groups/?$searchParams")
            .text()
            .await()
            .let { Conf.json.decodeFromString(it) }
    }

    suspend fun createGroup(studentsGroupDto: StudentsGroupDto): StudentsGroupDto {
        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/groups/",
            RequestInit(
                method = "POST",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
                body = Conf.json.encodeToString(studentsGroupDto),
            ),
        )
            .text()
            .await()

        return Conf.json.decodeFromString(response)
    }

    suspend fun updateGroup(studentsGroupDto: StudentsGroupDto) {
        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/groups/${studentsGroupDto.id}",
            RequestInit(
                method = "PUT",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
                body = Conf.json.encodeToString(studentsGroupDto),
            ),
        )

        if (!response.ok) {
            window.alert("Failed to update group")
            throw Exception("Failed to update group")
        }
    }

    suspend fun fetchGroupById(groupId: Long): StudentsGroupDto? {
        return authRetryFetchWrapper("${Conf.globalUrlBase}/v0/groups/$groupId")
            .text()
            .await()
            .let { Conf.json.decodeFromString(it) }
    }

    suspend fun fetchGroupWithStudentsAndCurators(groupId: Long): StudentsGroupWithStudentsAndCuratorsDto? {
        return authRetryFetchWrapper("${Conf.globalUrlBase}/v0/groups/$groupId/full")
            .text()
            .await()
            .let { Conf.json.decodeFromString(it) }
    }

    suspend fun removeCuratorFromGroup(groupId: Long, id: Long?) {
        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/groups/$groupId/removeCurator/$id",
            RequestInit(
                method = "DELETE",
            ),
        )

        if (!response.ok) {
            window.alert("Failed to remove curator from group")
            throw Exception("Failed to remove curator from group")
        }
    }

    suspend fun addCuratorToGroup(groupId: Long, curatorId: Long) {
        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/groups/$groupId/addCurator/$curatorId",
            RequestInit(
                method = "POST",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
            ),
        )

        if (!response.ok) {
            window.alert("Failed to add curator to group")
            throw Exception("Failed to add curator to group")
        }
    }

    suspend fun addStudentToGroup(groupId: Long, userToGroupConnectionInfoDto: IUserToGroupConnectionInfoDto) {
        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/groups/$groupId/students/${userToGroupConnectionInfoDto.userId}",
            RequestInit(
                method = "POST",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
                body = Conf.json.encodeToString(userToGroupConnectionInfoDto),
            ),
        )

        if (!response.ok) {
            window.alert("Failed to add student to group")
            throw Exception("Failed to add student to group")
        }
    }

    suspend fun updateStudentToGroup(groupId: Long, userToGroupConnectionInfoDto: IUserToGroupConnectionInfoDto) {
        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/groups/$groupId/students/${userToGroupConnectionInfoDto.userId}",
            RequestInit(
                method = "PUT",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                    append("Authorization", Api.getAuthHeader())
                },
                body = Conf.json.encodeToString(userToGroupConnectionInfoDto),
            ),
        )

        if (!response.ok) {
            window.alert("Failed to update student to group")
            throw Exception("Failed to update student to group")
        }
    }

    suspend fun removeStudentFromGroup(groupId: Long, userId: Long?) {
        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/groups/$groupId/students/$userId",
            RequestInit(
                method = "DELETE",
            ),
        )

        if (!response.ok) {
            window.alert("Failed to remove student from group")
            throw Exception("Failed to remove student from group")
        }
    }

    suspend fun getGroupLessons(groupId: Long, lessonState: LessonDto.State? = null): List<LessonToGroupDto> {
        val urlSearchParams = URLSearchParams().apply {
            lessonState?.let { append("state", it.name) }
        }

        val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/groups/$groupId/lessons?$urlSearchParams")
            .text()
            .await()

        return Conf.json.decodeFromString(response)
    }

    suspend fun recreateLessons(groupId: Long, lessonsToGroup: Collection<LessonToGroupDto>) {
        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/groups/$groupId/lessons/",
            RequestInit(
                method = "POST",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
                body = Conf.json.encodeToString(lessonsToGroup),
            ),
        )

        if (!response.ok) {
            window.alert("Failed to recreate lessons")
            throw Exception("Failed to recreate lessons")
        }
    }

    suspend fun getDefault(): StudentsGroupDto {
        val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/groups/default")
            .text()
            .await()

        return Conf.json.decodeFromString(response)
    }

    suspend fun setDefault(groupId: Long): StudentsGroupDto {
        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/groups/default/$groupId",
            RequestInit(
                method = "POST",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
            ),
        )

        if (!response.ok) {
            window.alert("Failed to set default group")
            throw Exception("Failed to set default group")
        }

        return Conf.json.decodeFromString(response.text().await())
    }

    suspend fun deleteDefault() {
        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/groups/default",
            RequestInit(
                method = "DELETE",
            ),
        )

        if (!response.ok) {
            window.alert("Failed to delete default group")
            throw Exception("Failed to delete default group")
        }
    }
}
