package curator

import adm.groups.LessonToGroupDto
import adm.groups.StudentsGroupDto
import adm.lessons.*
import adm.users.UserAdminApi.getUsersContactDetailBatch
import auth.UserContactDetailsDto
import auth.UserDto
import configuration.Conf.CURATOR_RELOAD_DELAY
import emotion.react.css
import js.core.jso
import kotlinx.coroutines.*
import react.*
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.details
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.main
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.select
import react.dom.html.ReactHTML.summary
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.tbody
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.th
import react.dom.html.ReactHTML.title
import react.dom.html.ReactHTML.tr
import react.router.dom.Link
import tasks.edit.KnowledgeDto
import utils.borderCss
import utils.useActionAwayOverlay
import web.cssom.*
import web.html.HTMLSelectElement
import kotlin.js.Date

private const val STUDENT_KNOWLEDGE_STATUS_OVERLAY_ID = "student-knowledge-status-overlay"
private const val BUTTON_SHOW_STUDENT_KNOWLEDGE_STATUS_ID = "button-show-student-knowledge-status"
private const val STUDENT_TEST_LESSON_STATUS_OVERLAY_ID = "student-test-lesson-status-overlay"
private const val BUTTON_SHOW_STUDENT_TEST_LESSON_STATUS_ID = "button-show-student-test-lesson-status"
private val TABLE_BORDER = Border(1.px, LineStyle.solid)

val CuratorGroupsPage = FC<Props> {
    var groups: List<StudentsGroupDto> by useState(emptyList())
    val groupLessons: MutableMap<Long, List<LessonToGroupDto>> by useState(mutableMapOf())
    var currentProgress: LessonProgressDto? by useState(null)
    var testLessonProgress: GroupTestAttemptsDto? by useState(null)
    val groupSelectRef = useRef<HTMLSelectElement>()
    val lessonSelectRef = useRef<HTMLSelectElement>()
    var currentGroupIdState: Long? by useState(null)
    var currentLesson: LessonToGroupDto? by useState(null)
    var currentLessonKnowledges: List<LessonToKnowledgeInfoWithKnowledgeBodyDto> by useState(emptyList())
    var showStudentKnowledgeStatusOverlay: Boolean by useState(false)
    var showStudentTestLessonStatusOverlay: Boolean by useState(false)
    var currentStudentKnowledgeStatus: StudentLessonKnowledgeStatusDto? by useState(null)
    var currentStudentKnowledgeStatusUser: UserDto? by useState(null)
    var currentStudentTestLessonStatus: GroupUserTestAttemptDto? by useState(null)
    var currentKnowledgeId: Long? by useState(null)
    var studentsContactInfos: List<UserContactDetailsDto> by useState(emptyList())
    var shouldRecheck: Boolean by useState(false)

    useActionAwayOverlay(
        STUDENT_KNOWLEDGE_STATUS_OVERLAY_ID,
        setOf(BUTTON_SHOW_STUDENT_KNOWLEDGE_STATUS_ID),
    ) {
        showStudentKnowledgeStatusOverlay = false
    }

    useActionAwayOverlay(
        STUDENT_TEST_LESSON_STATUS_OVERLAY_ID,
        setOf(BUTTON_SHOW_STUDENT_TEST_LESSON_STATUS_ID),
    ) {
        showStudentTestLessonStatusOverlay = false
    }

    suspend fun fetchProgress(groupId: Long, lessonId: Long): LessonProgressDto {
        val progress = CuratorApi.getLessonProgress(groupId, lessonId)
        currentProgress = progress
        return progress
    }

    suspend fun fetchTestLessonProgress(groupId: Long, lessonId: Long): GroupTestAttemptsDto {
        val progress = CuratorApi.fetchTestLessonProgress(groupId, lessonId)
        testLessonProgress = progress
        return progress
    }

    suspend fun fetchLessonKnowledges(lessonId: Long) {
        MainScope().launch {
            val knowledges = LessonsApi.fetchLessonToKnowledgeInfos(lessonId, true)
            currentLessonKnowledges = knowledges
        }
    }

    fun onChangeReload(
        actualGroupId: Long? = currentGroupIdState,
        lessons: List<LessonToGroupDto>? = null,
        isGroupChanged: Boolean = false,
    ) {
        val lessonId = if (isGroupChanged) {
            when {
                lessons != null -> lessons.minByOrNull { it.orderNumber }?.lessonId
                actualGroupId != null -> groupLessons[actualGroupId]?.minByOrNull { it.orderNumber }?.lessonId
                else -> null
            }
        } else {
            try {
                lessonSelectRef.current?.value?.toLong()
            } catch (_: NumberFormatException) {
                null
            }
        }

        if (lessonId == null) {
            currentLesson = null
            currentProgress = null
            return
        }

        val lesson = (lessons ?: groupLessons[actualGroupId])?.find { it.lessonId == lessonId }

        MainScope().launch {
            val jobs = listOf(
                launch {
                    if (lesson != null) {
                        val studentIds = when (lesson.lesson!!.type) {
                            LessonDto.Type.TEST -> {
                                val progress = fetchTestLessonProgress(actualGroupId!!, lessonId)
                                progress.studentsAttempts.map { it.user.id!! }
                            }

                            LessonDto.Type.REGULAR -> {
                                val progress = fetchProgress(actualGroupId!!, lessonId)
                                progress.studentsProgress.map { it.student.id!! }
                            }
                        }
                        studentsContactInfos = getUsersContactDetailBatch(studentIds)
                    }
                },
                launch { fetchLessonKnowledges(lessonId) },
            )

            jobs.joinAll()
            currentLesson = lesson
        }
    }

    useEffectOnce {
        MainScope().launch {
            val groupsAndLessons = CuratorApi.getGroupWithLessons()
            val newGroups = groupsAndLessons.map { it.group }
            val newGroupLessons = groupsAndLessons.associate { groupAndLesson ->
                groupAndLesson.group.id!! to groupAndLesson.lessons
            }
                .toMutableMap()

            currentGroupIdState = newGroups.first().id
            groups = newGroups
            groupLessons.putAll(newGroupLessons)

            val firstLesson = newGroupLessons[newGroups.first().id!!]!!.minByOrNull { it.orderNumber }!!
            currentLesson = firstLesson
            val fetchProgressJob = async { fetchProgress(newGroups.first().id!!, firstLesson.lessonId!!) }
            val fetchLessonKnowledgesJob = async { fetchLessonKnowledges(firstLesson.lessonId!!) }

            val progress = fetchProgressJob.await()
            val studentIds = progress.studentsProgress.map { it.student.id!! }
            studentsContactInfos = getUsersContactDetailBatch(studentIds)
            fetchLessonKnowledgesJob.await()

            shouldRecheck = true
        }
    }

    useEffect(shouldRecheck) {
        if (shouldRecheck) {
            MainScope().launch {
                shouldRecheck = false
                delay(CURATOR_RELOAD_DELAY)
                onChangeReload()
                shouldRecheck = true
            }
        }
    }

    title {
        +"Группы куратора"
    }

    main {
        div {
            borderCss()
            className = if (showStudentKnowledgeStatusOverlay) ClassName("hidden-behind-overlay") else null

            h1 {
                +"Группы куратора"
            }

            div {
                div {
                    +"Группа:"

                    select {
                        ref = groupSelectRef
                        groups.forEach { group ->
                            option {
                                value = group.id.toString()
                                +group.name
                            }
                        }

                        onChange = {
                            val groupId = groupSelectRef.current!!.value.toLong()
                            var currentGroupLessons: List<LessonToGroupDto>? = null

                            MainScope().launch {
                                currentGroupLessons = CuratorApi.getLessons(groupId)
                                groupLessons[groupId] = currentGroupLessons!!
                                currentGroupIdState = groupId
                            }

                            onChangeReload(groupId, currentGroupLessons, true)
                        }
                    }
                }

                div {
                    +"Урок группы:"

                    select {
                        ref = lessonSelectRef

                        currentGroupIdState?.let { gid ->
                            groupLessons[gid]?.sortedBy { it.orderNumber }
                                ?.forEach { lesson ->
                                    option {
                                        value = lesson.lessonId.toString()
                                        +lesson.lesson?.name
                                        when (lesson.lesson?.type) {
                                            LessonDto.Type.TEST -> +" (Тест)"
                                            else -> +""
                                        }
                                    }
                                }
                        }

                        onChange = {
                            onChangeReload()
                        }
                    }
                }

                br { }

                if (currentLessonKnowledges.isNotEmpty() && currentProgress != null && currentLesson != null) {
                    table {
                        css {
                            border = TABLE_BORDER
                        }

                        tbody {
                            tr {
                                th {
                                    css {
                                        border = TABLE_BORDER
                                    }

                                    +"Ученик\\Задание"
                                }

                                currentLessonKnowledges.sortedBy { it.orderNumber }
                                    .forEachIndexed { idx, knowledgeBodyDto ->
                                        if (knowledgeBodyDto.knowledge.type != KnowledgeDto.Type.THEORY) {
                                            th {
                                                css {
                                                    border = TABLE_BORDER
                                                }

                                                +"${idx + 1}"
                                            }
                                        }
                                    }
                            }

                            when (currentLesson?.lesson?.type) {
                                LessonDto.Type.REGULAR ->
                                    currentProgress
                                        ?.let { (_, lessonStatuses) ->
                                            for (lessonStatus in lessonStatuses) {
                                                tr {
                                                    td {
                                                        css {
                                                            border = TABLE_BORDER
                                                        }

                                                        +lessonStatus.student.username
                                                    }

                                                    lessonStatus.statuses.sortedBy { status ->
                                                        currentLessonKnowledges.firstOrNull { it.knowledge.id == status.knowledgeId }?.orderNumber
                                                            ?: 0
                                                    }.forEach { status ->
                                                        if (currentLessonKnowledges.firstOrNull { it.knowledge.id == status.knowledgeId }?.knowledge?.type != KnowledgeDto.Type.THEORY) {
                                                            td {
                                                                css {
                                                                    border = TABLE_BORDER
                                                                }

                                                                button {
                                                                    css {
                                                                        backgroundColor = when (status.status) {
                                                                            StudentLessonKnowledgeStatusDto.Status.IN_PROGRESS -> Color(
                                                                                "orange",
                                                                            )

                                                                            StudentLessonKnowledgeStatusDto.Status.COMPLETED -> Color(
                                                                                "green",
                                                                            )

                                                                            StudentLessonKnowledgeStatusDto.Status.NOT_STARTED -> null
                                                                        }

                                                                        height = 24.px
                                                                        width = 24.px
                                                                    }

                                                                    id = BUTTON_SHOW_STUDENT_KNOWLEDGE_STATUS_ID

                                                                    onClick = {
                                                                        showStudentKnowledgeStatusOverlay = true
                                                                        currentStudentKnowledgeStatus = status
                                                                        currentStudentKnowledgeStatusUser =
                                                                            lessonStatus.student
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                LessonDto.Type.TEST ->
                                    testLessonProgress
                                        ?.let { (_, studentsAttempts) ->
                                            for (attempt in studentsAttempts) {
                                                tr {
                                                    td {
                                                        css {
                                                            border = TABLE_BORDER
                                                        }

                                                        +attempt.user.username
                                                    }

                                                    val lastAttempts = attempt.attempts.groupBy { it.knowledgeId }
                                                        .map { it.value.maxBy { it.createdAt } }

                                                    lastAttempts.sortedBy { attempt ->
                                                        currentLessonKnowledges.firstOrNull { it.knowledge.id == attempt.knowledgeId }?.orderNumber
                                                            ?: 0
                                                    }.forEach { attempt ->
                                                        val knowledge =
                                                            currentLessonKnowledges.firstOrNull { it.knowledge.id == attempt.knowledgeId }?.knowledge
                                                        if (knowledge != null && knowledge.type != KnowledgeDto.Type.THEORY) {
                                                            td {
                                                                css {
                                                                    border = TABLE_BORDER
                                                                }

                                                                button {
                                                                    css {
                                                                        if (attempt.createdAt != 0L) {
                                                                            backgroundColor = when (attempt.answer) {
                                                                                knowledge.answer -> Color(
                                                                                    "green",
                                                                                )

                                                                                else -> Color("orange")
                                                                            }
                                                                        }

                                                                        height = 24.px
                                                                        width = 24.px
                                                                    }

                                                                    id = BUTTON_SHOW_STUDENT_TEST_LESSON_STATUS_ID

                                                                    onClick = {
                                                                        showStudentTestLessonStatusOverlay = true
                                                                        currentStudentTestLessonStatus =
                                                                            testLessonProgress!!.studentsAttempts.first { it.user.id == attempt.userId }
                                                                        currentKnowledgeId = attempt.knowledgeId
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    td {
                                                        css {
                                                            border = TABLE_BORDER
                                                        }

                                                        when (attempt.status.status) {
                                                            TestLessonUserStatusDto.Status.FINISHED -> +"Завершен"
                                                            TestLessonUserStatusDto.Status.STARTED -> {
                                                                val now = Date.now().toLong()
                                                                val startTs = attempt.status.createdAt
                                                                val timeToTest =
                                                                    (currentLesson?.lesson?.payload as TestLessonPayloadDto).timeToTest
                                                                val timeLeft = timeToTest + startTs - now

                                                                +"${timeLeft / 60 / 60 / 1000}:${timeLeft / 60 / 1000 % 60}:${timeLeft / 1000 % 60}"
                                                            }

                                                            TestLessonUserStatusDto.Status.NOT_STARTED -> +"Не начат"
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                else -> {}
                            }
                        }
                    }
                    br { }

                    details {
                        summary {
                            +"Задания"
                        }

                        br { }

                        currentLessonKnowledges.sortedBy { it.orderNumber }.forEach { knowledge ->
                            details {
                                summary {
                                    +"${knowledge.orderNumber}. "
                                    +when (knowledge.knowledge.type) {
                                        KnowledgeDto.Type.THEORY -> "Теория"
                                        KnowledgeDto.Type.TASK_VISIBLE_ANSWER -> "Задание со видимым ответом"
                                        KnowledgeDto.Type.TASK_HIDDEN_ANSWER -> "Задание со скрытым ответом"
                                    }
                                    +". ${knowledge.knowledge.title}"
                                }

                                div {
                                    borderCss()
                                    div {
                                        dangerouslySetInnerHTML = jso {
                                            __html = knowledge.knowledge.body
                                        }
                                    }

                                    if (knowledge.knowledge.type != KnowledgeDto.Type.THEORY) {
                                        br { }

                                        p {
                                            +"Ответ: "
                                            +knowledge.knowledge.answer
                                        }
                                    }
                                }
                            }

                            br { }
                        }
                    }
                }
            }
        }
    }

    div {
        className = ClassName(if (showStudentKnowledgeStatusOverlay) "overlay" else "overlay-hidden")

        div {
            id = STUDENT_KNOWLEDGE_STATUS_OVERLAY_ID
            className = ClassName("inner-overlay-div")

            div {
                borderCss()
                h1 {
                    +"Статус знаний ученика"
                }
                div {
                    +"Ученик: "
                    +currentStudentKnowledgeStatusUser?.username
                }
                div {
                    +"Задание: "
                    +currentLessonKnowledges.firstOrNull { it.knowledge.id == currentStudentKnowledgeStatus?.knowledgeId }?.knowledge?.title
                }
                div {
                    +"Статус: "
                    +currentStudentKnowledgeStatus?.status?.name
                }
                div {
                    +"Попытки: "
                    +currentStudentKnowledgeStatus?.attempts.toString()
                }
                div {
                    +"Последнее обновление: "
                    +Date(currentStudentKnowledgeStatus?.updatedAt ?: 0).toString()
                }
                div {
                    +"Контакты: "
                    table {
                        tbody {
                            studentsContactInfos
                                .filter { it.userId == currentStudentKnowledgeStatus?.userId }
                                .forEach { contactInfo ->
                                    tr {
                                        td {
                                            +contactInfo.type.humanReadableName
                                        }
                                        td {
                                            when (contactInfo.type) {
                                                UserContactDetailsDto.ContactDetailsType.TELEGRAM -> Link {
                                                    to = "tg://resolve?domain=${contactInfo.value}"
                                                    +contactInfo.value
                                                }

                                                else -> +contactInfo.value
                                            }
                                        }
                                    }
                                }
                        }
                    }
                }
            }
            div {
                borderCss()

                val knowledge =
                    currentLessonKnowledges.firstOrNull { it.knowledge.id == currentStudentKnowledgeStatus?.knowledgeId }

                if (knowledge != null) {
                    details {
                        summary {
                            +"${knowledge.orderNumber}. "
                            +when (knowledge.knowledge.type) {
                                KnowledgeDto.Type.THEORY -> "Теория"
                                KnowledgeDto.Type.TASK_VISIBLE_ANSWER -> "Задание с видимым ответом"
                                KnowledgeDto.Type.TASK_HIDDEN_ANSWER -> "Задание с скрытым ответом"
                            }
                            +". ${knowledge.knowledge.title}"
                        }

                        div {
                            borderCss()
                            div {
                                dangerouslySetInnerHTML = jso {
                                    __html = knowledge.knowledge.body
                                }
                            }

                            if (knowledge.knowledge.type != KnowledgeDto.Type.THEORY) {
                                br { }

                                p {
                                    +"Ответ: "
                                    +knowledge.knowledge.answer
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    div {
        className = ClassName(if (showStudentTestLessonStatusOverlay) "overlay" else "overlay-hidden")

        div {
            id = STUDENT_TEST_LESSON_STATUS_OVERLAY_ID
            className = ClassName("inner-overlay-div")

            if (currentStudentTestLessonStatus != null && currentKnowledgeId != null) {
                val knowledge = currentLessonKnowledges.firstOrNull { it.knowledge.id == currentKnowledgeId }?.knowledge
                val currentKnowledgeAnswers =
                    currentStudentTestLessonStatus!!.attempts.filter { it.knowledgeId == currentKnowledgeId }
                        .sortedBy { -it.createdAt }

                if (knowledge != null) {
                    div {
                        borderCss()
                        h1 {
                            +"Статус прохождения теста учеником"
                        }
                        div {
                            +"Ученик: "
                            +currentStudentTestLessonStatus?.user?.username
                        }
                        div {
                            +"Статус: "
                            +currentStudentTestLessonStatus?.status?.status?.name
                        }
                        div {
                            borderCss()

                            +"Попытки: "

                            table {
                                tbody {
                                    currentKnowledgeAnswers.forEach { attempt ->
                                        tr {
                                            td {
                                                css {
                                                    backgroundColor = when (attempt.answer) {
                                                        knowledge.answer -> Color("green")
                                                        else -> Color("orange")
                                                    }
                                                }

                                                +attempt.answer
                                            }
                                            td {
                                                +Date(attempt.createdAt).toString()
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        div {
                            +"Контакты: "
                            table {
                                tbody {
                                    studentsContactInfos
                                        .filter { it.userId == currentStudentTestLessonStatus?.user?.id }
                                        .forEach { contactInfo ->
                                            tr {
                                                td {
                                                    +contactInfo.type.humanReadableName
                                                }
                                                td {
                                                    when (contactInfo.type) {
                                                        UserContactDetailsDto.ContactDetailsType.TELEGRAM -> Link {
                                                            to = "tg://resolve?domain=${contactInfo.value}"
                                                            +contactInfo.value
                                                        }

                                                        else -> +contactInfo.value
                                                    }
                                                }
                                            }
                                        }
                                }
                            }
                        }
                    }

                    div {
                        borderCss()

                        val knowledgeInLessonInfo =
                            currentLessonKnowledges.firstOrNull { it.knowledge.id == currentKnowledgeId }

                        if (knowledgeInLessonInfo != null) {
                            details {
                                summary {
                                    +"${knowledgeInLessonInfo.orderNumber}. "
                                    +when (knowledgeInLessonInfo.knowledge.type) {
                                        KnowledgeDto.Type.THEORY -> "Теория"
                                        else -> "Задание"
                                    }
                                    +". ${knowledgeInLessonInfo.knowledge.title}"
                                }

                                div {
                                    borderCss()
                                    div {
                                        dangerouslySetInnerHTML = jso {
                                            __html = knowledgeInLessonInfo.knowledge.body
                                        }
                                    }

                                    if (knowledgeInLessonInfo.knowledge.type != KnowledgeDto.Type.THEORY) {
                                        br { }

                                        p {
                                            +"Ответ: "
                                            +knowledgeInLessonInfo.knowledge.answer
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
