package adm.lessons

import emotion.react.css
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.*
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.th
import react.dom.html.ReactHTML.tr
import react.router.dom.Link
import utils.fallbackIfNotTeacher
import web.cssom.BorderCollapse
import web.cssom.px
import web.html.HTMLSelectElement
import web.location.location
import kotlin.js.Date

val LessonsListComponent = FC<Props> { props ->
    lessonListComponentBuilder(props) { lesson ->
        return@lessonListComponentBuilder {
            Link {
                +"Edit"

                to = "/admin/lessons/edit/?id=${lesson.id}"
            }
        }
    }
}

fun ChildrenBuilder.lessonListComponentBuilder(
    props: Props,
    securityFallback: suspend () -> Unit = { fallbackIfNotTeacher() },
    actionsColumn: (LessonDto) -> (@ReactDsl ChildrenBuilder.() -> Unit),
) {
    var lessons: List<LessonDto> by useState(emptyList())
    val stateRef = useRef<HTMLSelectElement>()
    var typeRef = useRef<HTMLSelectElement>()
    var pageState: Int by useState(0)

    useEffectOnce {
        MainScope().launch {
            securityFallback()
        }
    }

    div {
        +"Lessons list"
    }

    stateSelect(stateRef)
    typeSelect(typeRef)

    button {
        +"Search"
        onClick = {
            MainScope().launch {
                lessons = LessonsApi.searchLessons(state = lessonStateRefToEnum(stateRef), page = pageState, type = lessonTypeRefToEnum(typeRef))
            }
        }
    }
    button {
        +"Create"
        onClick = {
            location.href = "/admin/lessons/add/"
        }
    }

    div {
        table {
            css {
                borderCollapse = BorderCollapse.separate
                borderSpacing = 50.px
            }

            tr {
                th {
                    +"Name"
                }
                th {
                    +"Publish at"
                }
                th {
                    +"Task number"
                }
                th {
                    +"State"
                }
                th {
                    +"Type"
                }
                th {
                    +"Actions"
                }
            }
            lessons.forEach { lesson ->
                tr {
                    th {
                        +lesson.name
                    }
                    th {
                        +Date(lesson.publishAt).toLocaleString()
                    }
                    th {
                        +lesson.taskNumber.toString()
                    }
                    th {
                        +lesson.state.toString()
                    }
                    th {
                        +lesson.type.name
                    }
                    th(actionsColumn(lesson))
                }
            }
        }
    }

    div {
        button {
            +"Prev"
            onClick = {
                if (pageState > 0) {
                    MainScope().launch {
                        lessons = LessonsApi.searchLessons(
                            page = pageState - 1,
                            state = lessonStateRefToEnum(stateRef),
                            type = lessonTypeRefToEnum(typeRef),
                        )
                        pageState--
                    }
                }
            }
        }
        +pageState.toString()
        button {
            +"Next"
            onClick = {
                MainScope().launch {
                    lessons = LessonsApi.searchLessons(
                        page = pageState + 1,
                        state = lessonStateRefToEnum(stateRef),
                        type = lessonTypeRefToEnum(typeRef),
                    )
                    pageState++
                }
            }
        }
    }
}
