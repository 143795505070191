package tasks.edit

import react.ChildrenBuilder
import react.MutableRefObject
import react.Ref
import react.dom.events.ChangeEventHandler
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import web.html.HTMLInputElement
import web.html.HTMLSelectElement
import web.html.InputType

fun ChildrenBuilder.selectTaskNumber(
    taskNumber: MutableRefObject<HTMLInputElement>,
    default: Int? = null,
) {
    div {
        +"Task number: "

        input {
            ref = taskNumber
            defaultValue = default?.toString()
            type = InputType.number
        }
    }
}

fun ChildrenBuilder.selectDifficulty(
    difficulty: Ref<HTMLSelectElement>?,
    default: String? = null,
) {
    div {
        +"Difficulty: "

        select {
            ref = difficulty

            defaultValue = default
            option {
                value = null
                +"Any"
            }
            option {
                value = KnowledgeDto.Difficulty.EASY
                +"Easy"
            }
            option {
                value = KnowledgeDto.Difficulty.MEDIUM
                +"Medium"
            }
            option {
                value = KnowledgeDto.Difficulty.HARD
                +"Hard"
            }

            +"Difficulty"
        }
    }
}

fun ChildrenBuilder.selectType(
    typeRef: Ref<HTMLSelectElement>?,
    default: String? = null,
    onChangeFun: ChangeEventHandler<HTMLSelectElement>? = null,
) {
    div {
        +"Type: "

        select {
            ref = typeRef

            defaultValue = default
            option {
                value = null
                +"Any"
            }
            option {
                value = KnowledgeDto.Type.THEORY
                +"Theory"
            }
            option {
                value = KnowledgeDto.Type.TASK_VISIBLE_ANSWER
                +"Task visible answer"
            }
            option {
                value = KnowledgeDto.Type.TASK_HIDDEN_ANSWER
                +"Task hidden answer"
            }

            onChange = onChangeFun
        }
    }
}

fun ChildrenBuilder.selectState(
    stateRef: Ref<HTMLSelectElement>?,
    default: String? = null,
) {
    div {
        +"State: "

        select {
            ref = stateRef

            defaultValue = default
            option {
                value = null
                +"Any"
            }
            option {
                value = KnowledgeDto.State.DRAFT
                +"Draft"
            }
            option {
                value = KnowledgeDto.State.PUBLISHED
                +"Published"
            }
        }
    }
}
