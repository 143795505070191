package client

import adm.lessons.*
import emotion.react.css
import js.core.jso
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.async
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.w3c.dom.url.URLSearchParams
import react.*
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.main
import react.dom.html.ReactHTML.title
import tasks.edit.KnowledgeDto
import utils.borderCss
import web.cssom.Color
import web.cssom.Cursor
import web.cssom.em
import web.html.HTMLInputElement
import web.location.location
import kotlin.js.Date
import kotlin.math.max

val TestLessonComponent = FC<Props> {
    var lesson: LessonDto? by useState(null)
    var testLessonStatus: TestLessonUserStatusDto? by useState(null)
    var timeLeft: Long by useState(0)
    var knowledges: List<LessonToKnowledgeInfoWithKnowledgeBodyDto> by useState(emptyList())
    var timerTrigger: Boolean by useState(false)
    var attemptsByKnowledge: Map<Long, TestLessonAnswerAttemptDto> by useState(emptyMap())
    var knowledgePosition: Int by useState(1)
    val answerRef = useRef<HTMLInputElement>()
    var validateFinishTest: Boolean by useState(false)
    var testLessonResult: TestLessonResultDto? by useState(null)

    useEffectOnce {
        val lessonId = URLSearchParams(location.search).get("lessonId")!!.toLong()

        MainScope().launch {
            val lessonInfoJob = async {
                val lessonTmp = LessonsApi.fetchLesson(lessonId)
                lesson = lessonTmp
                lessonTmp
            }
            val testLessonStatusJob = async {
                val testLessonStatusTmp = LessonsApi.fetchTestLessonStatus(lessonId)
                testLessonStatus = testLessonStatusTmp
                testLessonStatusTmp
            }

            lessonInfoJob.await()
            val statusTmp = testLessonStatusJob.await()
            if (statusTmp.status == TestLessonUserStatusDto.Status.STARTED) {
                val knowledgesTmp = LessonsApi.fetchLessonToKnowledgeInfos(lessonId)
                knowledges = knowledgesTmp
                val attemptsByKnowledgeTmp = LessonsApi.fetchTestLessonAnswerMyAttempts(
                    lessonId,
                ).associateBy { it.knowledgeId }
                attemptsByKnowledge = attemptsByKnowledgeTmp

                knowledgePosition =
                    attemptsByKnowledge.values.maxByOrNull { it.createdAt }?.knowledgeId?.let { knowledgeId ->
                        knowledgesTmp.firstOrNull { it.knowledge.id == knowledgeId }?.orderNumber
                    } ?: 1
            }

            if (statusTmp.status == TestLessonUserStatusDto.Status.FINISHED) {
                testLessonResult = LessonsApi.fetchTestLessonResult(lessonId)
            }
        }
    }

    useEffect(knowledgePosition) {
        if (attemptsByKnowledge.isNotEmpty() && knowledges.isNotEmpty()) {
            if (answerRef.current != null) {
                val knowledgeId = knowledges.first { it.orderNumber == knowledgePosition }.knowledge.id
                val answer = attemptsByKnowledge[knowledgeId]?.answer
                answerRef.current!!.value = answer ?: ""
            }
        }
    }

    useEffect(lesson, testLessonStatus) {
        if (lesson != null && testLessonStatus != null) {
            if (testLessonStatus!!.status == TestLessonUserStatusDto.Status.STARTED) {
                timeLeft =
                    testLessonStatus!!.createdAt + (lesson!!.payload as TestLessonPayloadDto).timeToTest - Date.now()
                        .toLong()
                MainScope().launch {
                    delay(1000)
                    timerTrigger = true
                }
            }
        }
    }

    useEffect(timerTrigger) {
        if (timerTrigger) {
            MainScope().launch {
                timerTrigger = false
                val tmpTime = max(0, timeLeft - 1000)
                timeLeft = tmpTime
                delay(1000)
                timerTrigger = true
            }
        }
    }

    title {
        +"Тест"
    }

    fun ChildrenBuilder.startTestButton() {
        h2 {
            +"Время на тест: ${(lesson!!.payload as TestLessonPayloadDto).timeToTest / 60_000} минут"
        }

        div {
            borderCss()
            button {
                +"Начать тест"
                onClick = {
                    MainScope().launch {
                        val testLessonStatusTmp = LessonsApi.startTestLesson(lesson!!.id!!)
                        testLessonStatus = testLessonStatusTmp
                        val knowledgesTmp = LessonsApi.fetchLessonToKnowledgeInfos(lesson!!.id!!)
                        knowledges = knowledgesTmp
                        val attemptsByKnowledgeTmp = LessonsApi.fetchTestLessonAnswerMyAttempts(
                            lesson!!.id!!,
                        ).associateBy { it.knowledgeId }
                        attemptsByKnowledge = attemptsByKnowledgeTmp

                        knowledgePosition =
                            attemptsByKnowledge.values.maxByOrNull { it.createdAt }?.knowledgeId?.let { knowledgeId ->
                                knowledgesTmp.firstOrNull { it.knowledge.id == knowledgeId }?.orderNumber
                            } ?: 1
                    }
                }
            }
        }
    }

    fun ChildrenBuilder.renderProgressBar() {
        div {
            borderCss()

            for (knowledge in knowledges.sortedBy { it.orderNumber }) {
                button {
                    css {
                        backgroundColor =
                            when (attemptsByKnowledge.values.firstOrNull { it.knowledgeId == knowledge.knowledge.id }?.id) {
                                null -> null
                                else -> Color("yellow")
                            }
                        cursor = if (knowledgePosition == knowledge.orderNumber) Cursor.notAllowed else null
                        fontSize = 2.0.em
                    }

                    disabled = knowledgePosition == knowledge.orderNumber
                    +knowledge.orderNumber.toString()
                    onClick = {
                        knowledgePosition = knowledge.orderNumber
                    }
                }
            }
        }
    }

    fun ChildrenBuilder.renderAnswerBox(knowledge: LessonToKnowledgeInfoWithKnowledgeBodyDto) {
        fun sendAnswer() {
            MainScope().launch {
                val attempt = LessonsApi.answerToKnowledeInTestLesson(
                    lesson!!.id!!,
                    knowledge.knowledge.id!!,
                    answerRef.current!!.value,
                )
                attemptsByKnowledge = attemptsByKnowledge + (knowledge.knowledge.id to attempt)
            }
        }

        fun ChildrenBuilder.answerBox() {
            div {
                div {
                    input {
                        defaultValue = attemptsByKnowledge[knowledge.knowledge.id!!]?.answer ?: ""
                        ref = answerRef
                    }
                }

                button {
                    +"Ответить"
                    onClick = {
                        sendAnswer()
                    }
                }
            }
        }

        div {
            when (knowledge.knowledge.type) {
                KnowledgeDto.Type.TASK_HIDDEN_ANSWER -> {
                    answerBox()
                }

                KnowledgeDto.Type.TASK_VISIBLE_ANSWER -> {
                    answerBox()
                }

                KnowledgeDto.Type.THEORY -> {
                    button {
                        +"Пометить пройденым"

                        onClick = {
                            MainScope().launch {
                                val attempt =
                                    LessonsApi.answerToKnowledeInTestLesson(lesson!!.id!!, knowledge.knowledge.id!!, "")
                                attemptsByKnowledge = attemptsByKnowledge + (knowledge.knowledge.id to attempt)
                            }
                        }
                    }
                }
            }
        }
    }

    fun ChildrenBuilder.renderKnowledge() {
        div {
            borderCss()

            val knowledge = knowledges.first { it.orderNumber == knowledgePosition }

            h2 {
                +"${knowledge.orderNumber}. "
                when (knowledge.knowledge.type) {
                    KnowledgeDto.Type.TASK_HIDDEN_ANSWER -> {
                        +"Задача."
                    }

                    KnowledgeDto.Type.TASK_VISIBLE_ANSWER -> {
                        +"Задача."
                    }

                    KnowledgeDto.Type.THEORY -> {
                        +"Теория."
                    }
                }
            }

            renderProgressBar()

            div {
                div {
                    dangerouslySetInnerHTML = jso {
                        __html = knowledge.knowledge.body
                    }
                }

                renderAnswerBox(knowledge)

                div {
                    if (knowledgePosition > 1) {
                        button {
                            +"Назад"
                            onClick = {
                                knowledgePosition--
                            }
                        }
                    }
                    if (knowledgePosition < knowledges.maxByOrNull { it.orderNumber }!!.orderNumber) {
                        button {
                            +"Вперед"
                            onClick = {
                                knowledgePosition++
                            }
                        }
                    }
                }
            }
        }
    }

    fun ChildrenBuilder.inProgress() {
        h2 {
            +"Осталось времени на тест: ${timeLeft / 60 / 60 / 1000}:${timeLeft / 60 / 1000 % 60}:${timeLeft / 1000 % 60}"

            div {
                if (validateFinishTest) {
                    +"Подтвердите завершение теста"
                    button {
                        +"Подтвердить"
                        onClick = {
                            MainScope().launch {
                                LessonsApi.finishTestLesson(lesson!!.id!!)
                                location.reload()
                            }
                        }
                    }
                    button {
                        +"Отмена"
                        onClick = {
                            validateFinishTest = false
                        }
                    }
                } else {
                    button {
                        +"Закончить тест"
                        onClick = {
                            validateFinishTest = true
                        }
                    }
                }
            }
        }

        if (knowledges.isNotEmpty() && attemptsByKnowledge.isNotEmpty()) {
            renderKnowledge()
        }
    }

    fun ChildrenBuilder.finished() {
        h2 {
            +"Вы завершили тест"
        }

        if (testLessonResult != null) {
            div {
                borderCss()
                +"Ваш результат: ${testLessonResult!!.correctCount}/${testLessonResult!!.totalCount}"
            }
        }
    }

    main {
        if (lesson == null || testLessonStatus == null) {
            h1 {
                +"Загрузка..."
            }
        } else {
            h1 {
                +"Тест (${lesson!!.name})"
            }

            div {
                borderCss()

                div {
                    borderCss()
                    when (testLessonStatus!!.status) {
                        TestLessonUserStatusDto.Status.NOT_STARTED -> {
                            startTestButton()
                        }

                        TestLessonUserStatusDto.Status.STARTED -> {
                            inProgress()
                        }

                        TestLessonUserStatusDto.Status.FINISHED -> {
                            finished()
                        }
                    }
                }
            }
        }
    }
}
