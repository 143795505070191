package tasks.edit

import js.core.jso
import org.w3c.dom.url.URLSearchParams
import react.create
import react.router.RouteObject
import web.location.location

val tasksRoutes =
    jso<RouteObject> {
        path = "/tasks/"
        children = arrayOf(
            jso {
                index = true
                path = ""
                element = TaskListComponent.create()
            },
            jso {
                path = "edit/"
                element = TasksEditComponent(
                    URLSearchParams(location.search).get("id")?.toLong(),
                ).render()
            },
        )
    }
