package utils

import emotion.react.css
import react.PropsWithClassName
import web.cssom.Border
import web.cssom.Color
import web.cssom.LineStyle
import web.cssom.px

fun PropsWithClassName.borderCss() {
    css {
        border = Border(1.px, LineStyle.solid, Color("#000000"))
    }
}
