package adm

import adm.groups.CreateGroupComponent
import adm.groups.GroupsEditComponent
import adm.groups.GroupsListComponent
import adm.lessons.LessonAddComponent
import adm.lessons.LessonEditComponent
import adm.lessons.LessonsListComponent
import adm.users.UserCreateComponent
import adm.users.UserEditComponent
import adm.users.UsersListComponent
import js.core.jso
import org.w3c.dom.url.URLSearchParams
import react.create
import react.router.RouteObject
import web.location.location

val adminRoutes =
    jso<RouteObject> {
        path = "/admin/"
        children = arrayOf(
            jso {
                path = "users/"
                children = arrayOf(
                    jso {
                        index = true
                        path = ""
                        element = UsersListComponent.create()
                    },
                    jso {
                        path = "edit/"
                        element = UserEditComponent().render()
                    },
                    jso {
                        path = "add/"
                        element = UserCreateComponent.create()
                    },
                )
            },
            jso {
                path = "lessons/"
                children = arrayOf(
                    jso {
                        index = true
                        path = ""
                        element = LessonsListComponent.create()
                    },
                    jso {
                        path = "edit/"
                        element = LessonEditComponent().render()
                    },
                    jso {
                        path = "add/"
                        element = LessonAddComponent.create()
                    },
                )
            },
            jso {
                path = "groups/"
                children = arrayOf(
                    jso {
                        index = true
                        path = ""
                        element = GroupsListComponent.create()
                    },
                    jso {
                        path = "edit/"
                        val groupId = URLSearchParams(location.search).get("id")?.toLong()
                        element = GroupsEditComponent(groupId).render()
                    },
                    jso {
                        path = "add/"
                        element = CreateGroupComponent.create()
                    },
                )
            },
        )
    }
