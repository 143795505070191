package adm.users

import adm.users.UserAdminApi.addUserContactInfo
import adm.users.UserAdminApi.deleteUserContactInfo
import adm.users.UserAdminApi.getContactDetails
import adm.users.UserAdminApi.getUser
import auth.UserContactDetailsDto
import auth.UserDtoWithRoles
import auth.UserWithPassDto
import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import org.w3c.dom.url.URLSearchParams
import react.*
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.select
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.th
import react.dom.html.ReactHTML.tr
import utils.fallbackIfNotAdmin
import web.cssom.*
import web.html.HTMLInputElement
import web.html.HTMLSelectElement
import web.location.location

class UserEditComponent(
    private var userId: Long? = null,
    private val isProfilePage: Boolean = false,
) : Component<Props, State>() {
    private suspend fun fetchUser(): UserDtoWithRoles {
        return if (isProfilePage) {
            val user = auth.Api.me()
            userId = user.id
            user
        } else {
            getUser(userId ?: throw IllegalStateException("userId is null"))
        }
    }

    private suspend fun fetchContactDetails(): List<UserContactDetailsDto> {
        return getContactDetails(userId ?: throw IllegalStateException("userId is null"))
    }

    private suspend fun addContactDetails(contactDetails: UserContactDetailsDto) {
        addUserContactInfo(contactDetails)
    }

    private suspend fun deleteContactDetails(contactDetails: UserContactDetailsDto) {
        deleteUserContactInfo(contactDetails)
    }

    private suspend fun saveUser(user: UserWithPassDto) {
        if (!isProfilePage) {
            UserAdminApi.updateUser(user)
        } else {
            auth.Api.editMe(user)
        }
    }

    private suspend fun deleteRole(role: auth.RoleDto.Role) {
        UserAdminApi.deleteRole(userId ?: throw IllegalStateException("userId is null"), role)
    }

    private suspend fun addRole(role: auth.RoleDto.Role) {
        UserAdminApi.addRole(userId ?: throw IllegalStateException("userId is null"), role)
    }

    private fun PropsWithClassName.borderCss() {
        css {
            border = Border(1.px, LineStyle.solid, Color("#000000"))
        }
    }

    override fun render(): ReactNode = FC<Props> {
        var user: UserDtoWithRoles? by useState(null)
        var contactDetailsRef: List<UserContactDetailsDto> by useState(emptyList())
        val usernameRef = useRef<HTMLInputElement>()
        val passwordRef = useRef<HTMLInputElement>()
        val addRoleSelectorRef = useRef<HTMLSelectElement>()
        val contactDetailsTypeRef = useRef<HTMLSelectElement>()
        val contactDetailsValueRef = useRef<HTMLInputElement>()

        useEffectOnce {
            userId = URLSearchParams(location.search).get("id")?.toLong()

            MainScope().launch {
                user = fetchUser()
                contactDetailsRef = fetchContactDetails()
                if (!isProfilePage) {
                    fallbackIfNotAdmin()
                }
            }
        }

        div {
            if (user == null) {
                +"Загрузка..."
            } else {
                user!!.let { localUser ->
                    div {
                        p {
                            +"Id: ${localUser.id}"
                        }
                        // Username
                        div {
                            p {
                                +"Уникальное имя пользователя:"
                            }
                            input {
                                ref = usernameRef
                                defaultValue = localUser.username
                            }
                            p {
                                +"Пароль:"
                            }
                            input {
                                type = web.html.InputType.password
                                ref = passwordRef
                            }
                            br {}
                            button {
                                onClick = {
                                    MainScope().launch {
                                        val pass = if (passwordRef.current?.value == "") {
                                            null
                                        } else {
                                            passwordRef.current?.value
                                        }

                                        saveUser(
                                            UserWithPassDto(
                                                id = localUser.id,
                                                username = usernameRef.current?.value ?: localUser.username,
                                                password = pass,
                                            ),
                                        )
                                    }
                                }

                                +"Сохранить"
                            }
                        }

                        // Roles
                        if (!isProfilePage) {
                            div {
                                borderCss()
                                +"Роли"

                                table {
                                    tr {
                                        th {
                                            +"Роль"
                                        }
                                        th {
                                            +"Удалить"
                                        }
                                    }
                                    localUser.roles.forEach { role ->
                                        tr {
                                            td {
                                                +role.role.toString()
                                            }
                                            td {
                                                button {
                                                    onClick = {
                                                        MainScope().launch {
                                                            deleteRole(role.role)
                                                            user = localUser.copy(
                                                                roles = localUser.roles.filter { it.role != role.role },
                                                            )
                                                        }
                                                    }

                                                    +"Удалить"
                                                }
                                            }
                                        }
                                    }
                                }

                                div {
                                    +"Добавить роль"

                                    roleSelector(addRoleSelectorRef)

                                    button {
                                        onClick = {
                                            MainScope().launch {
                                                val newRole = userRoleRefToEnum(addRoleSelectorRef)
                                                if (newRole == null) {
                                                    window.alert("Выберите роль")
                                                    return@launch
                                                }

                                                addRole(newRole)
                                                user = localUser.copy(
                                                    roles = localUser.roles + auth.RoleDto(
                                                        role = newRole,
                                                    ),
                                                )
                                            }
                                        }

                                        +"Добавить"
                                    }
                                }
                            }
                        }

                        div {
                            borderCss()
                            +"Контактные данные"

                            table {
                                css {
                                    borderCollapse = BorderCollapse.separate
                                    borderSpacing = 50.px
                                }

                                tr {
                                    th {
                                        +"Тип контакта"
                                    }
                                    th {
                                        +"Контакт"
                                    }
                                    th {
                                        +"Действия"
                                    }
                                }
                                contactDetailsRef.forEach { contactDetails ->
                                    tr {
                                        td {
                                            +contactDetails.type.toString()
                                        }
                                        td {
                                            +contactDetails.value
                                        }
                                        td {
                                            if (!isProfilePage || contactDetails.type != UserContactDetailsDto.ContactDetailsType.TELEGRAM) {
                                                button {
                                                    onClick = {
                                                        MainScope().launch {
                                                            deleteContactDetails(contactDetails)
                                                            contactDetailsRef =
                                                                contactDetailsRef.filter { it != contactDetails }
                                                        }
                                                    }

                                                    +"Удалить"
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            div {
                                +"Добавить контакт"

                                div {
                                    +"Тип контакта"
                                    select {
                                        ref = contactDetailsTypeRef
                                        option {
                                            +"EMAIL"
                                        }
                                        option {
                                            +"PHONE"
                                        }
                                        option {
                                            +"TELEGRAM"
                                        }

                                        defaultValue = "EMAIL"
                                    }
                                }
                                div {
                                    +"Контакт"
                                    input {
                                        ref = contactDetailsValueRef
                                    }
                                }

                                button {
                                    onClick = {
                                        MainScope().launch {
                                            val newContactDetails = UserContactDetailsDto(
                                                id = null,
                                                userId = localUser.id,
                                                type = contactDetailsTypeRef.current?.value?.let {
                                                    try {
                                                        UserContactDetailsDto.ContactDetailsType.valueOf(it)
                                                    } catch (e: Exception) {
                                                        null
                                                    }
                                                } ?: UserContactDetailsDto.ContactDetailsType.EMAIL,
                                                value = contactDetailsValueRef.current?.value ?: "",
                                            )

                                            addContactDetails(newContactDetails)
                                            contactDetailsRef = contactDetailsRef + newContactDetails
                                        }
                                    }

                                    +"Добавить"
                                }
                            }
                        }
                    }
                }
            }
        }
    }.create()
}
