package auth

import emotion.react.css
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.main
import react.router.Outlet
import react.useRef
import react.useState
import utils.useActionAwayOverlay
import web.cssom.*
import web.html.HTMLInputElement
import web.html.InputType
import web.location.location

private val LOGIN_BUTTON_ID = "login-button"

val LoginComponent =
    FC<Props> {
        ReactHTML.title {
            +"Вход"
        }
        val password = useRef<HTMLInputElement>()
        val login = useRef<HTMLInputElement>()
        var isErrorOverlayVisible: Boolean by useState(false)

        fun doLogin() {
            MainScope().launch {
                val tokens =
                    try {
                        Api.auth(login.current?.value!!, password.current?.value!!)
                    } catch (e: Exception) {
                        isErrorOverlayVisible = true
                        return@launch
                    }

                if (tokens.access != null) {
                    location.href = "/"
                }
            }
        }

        useActionAwayOverlay(
            "NOTHING",
            setOf(LOGIN_BUTTON_ID),
        ) { isErrorOverlayVisible = false }

        div {
            className = if (isErrorOverlayVisible) ClassName("hidden-behind-overlay") else null

            main {
                css {
                    display = Display.flex
                    justifyContent = JustifyContent.center
                }

                div {
                    ReactHTML.h1 {
                        +"Войти"
                    }
                    div {
                        css {
                            display = Display.flex
                            flexDirection = FlexDirection.column
                        }
                        form {
                            onSubmit = {
                                it.preventDefault()
                                doLogin()
                            }

                            div {
                                ReactHTML.p {
                                    +"Имя пользователя:"
                                }
                                ReactHTML.input {
                                    type = InputType.text
                                    ref = login
                                }
                            }
                            div {
                                ReactHTML.p {
                                    +"Пароль:"
                                }
                                ReactHTML.input {
                                    type = InputType.password
                                    ref = password
                                }
                            }
                            div {
                                ReactHTML.button {
                                    +"Войти"
                                    onClick = {
                                        doLogin()
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        div {
            className = if (isErrorOverlayVisible) ClassName("overlay") else ClassName("overlay-hidden")

            div {
                className = ClassName("inner-overlay-div")

                div {
                    css {
                        color = Color("red")
                        textAlign = TextAlign.center
                        display = Display.flex
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                        fontSize = 3.em
                    }

                    ReactHTML.p {
                        +"Ошибка входа"
                    }
                }
            }
        }

        Outlet {}
    }
