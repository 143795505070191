package client

import js.core.jso
import org.w3c.dom.url.URLSearchParams
import react.create
import react.router.RouteObject
import web.location.location

val clientRoutes = jso<RouteObject> {
    path = ""
    children = arrayOf(
        jso {
            path = "groups/"
            children = arrayOf(
                jso {
                    index = true
                    path = ""
                    element = GroupsComponent.create()
                },
                jso {
                    path = "info"
                    val groupId = URLSearchParams(location.search).get("groupId")?.toLong()
                    element = GroupInfoComponent(groupId).render()
                },
            )
        },
        jso {
            path = "lesson/"
            children = arrayOf(
                jso {
                    path = ""
                    index = true
                    val lessonId = URLSearchParams(location.search).get("lessonId")?.toLong()
                    element = LessonComponent(lessonId).render()
                },
                jso {
                    path = "test"
                    element = TestLessonComponent.create()
                },
            )
        },
    )
}
