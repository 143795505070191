package adm.users

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.useEffectOnce
import react.useRef
import utils.fallbackIfNotAdmin
import web.html.HTMLInputElement
import web.html.InputType
import web.location.location

val UserCreateComponent = FC<Props> {
    val username = useRef<HTMLInputElement>()
    val password = useRef<HTMLInputElement>()

    useEffectOnce {
        MainScope().launch {
            fallbackIfNotAdmin()
        }
    }

    div {
        +"Create user"

        div {
            +"Username:"
            input {
                ref = username
            }
        }

        div {
            +"Password:"
            input {
                type = InputType.password
                ref = password
            }
        }

        button {
            +"Create"
            onClick = {
                MainScope().launch {
                    val id = UserAdminApi.createUser(username.current!!.value, password.current!!.value)

                    location.href = "/admin/users/edit/?id=$id"
                }
            }
        }
    }
}
