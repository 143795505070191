package feedback

import emotion.react.css
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.main
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import react.dom.html.ReactHTML.textarea
import react.useRef
import react.useState
import utils.useActionAwayOverlay
import web.cssom.*
import web.html.HTMLSelectElement
import web.html.HTMLTextAreaElement

private val SEND_BUTTON_ID = "send-button"

val FeedbackComponent =
    FC<Props> {
        val reasonRef = useRef<HTMLSelectElement>()
        val textareaRef = useRef<HTMLTextAreaElement>()
        var isSentOverlayVisible: Boolean by useState(false)

        useActionAwayOverlay(
            "NOTHING",
            setOf(SEND_BUTTON_ID),
        ) { isSentOverlayVisible = false }

        div {
            className = if (isSentOverlayVisible) ClassName("hidden-behind-overlay") else null

            main {
                h1 {
                    +"Связаться с нами"
                }

                div {
                    div {
                        +"Причина обращения:  "
                        select {
                            option {
                                +"Обратная связь"

                                value = FeedbackTypeDto.FEEDBACK
                            }

                            ref = reasonRef
                        }
                    }

                    div {
                        div {
                            +"Текст:"
                        }

                        textarea {
                            ref = textareaRef
                        }
                    }

                    div {
                        button {
                            id = SEND_BUTTON_ID

                            +"Отправить"

                            onClick = {
                                MainScope().launch {
                                    FeedbackApi.sendFeedback(
                                        FeedbackDto(
                                            id = null,
                                            type = FeedbackTypeDto.valueOf(reasonRef.current!!.value),
                                            text = textareaRef.current!!.value,
                                            createdAt = 0,
                                            userId = 0,
                                        ),
                                    )
                                }

                                isSentOverlayVisible = true
                            }
                        }
                    }
                }
            }
        }

        div {
            className = if (isSentOverlayVisible) ClassName("overlay") else ClassName("overlay-hidden")

            div {
                className = ClassName("inner-overlay-div")

                div {
                    css {
                        textAlign = TextAlign.center
                        display = Display.flex
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                        fontSize = 3.em
                    }

                    ReactHTML.p {
                        +"Обращение отправлено"
                    }
                }
            }
        }
    }
