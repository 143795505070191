package adm.lessons

import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import org.w3c.dom.url.URLSearchParams
import react.*
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.th
import react.dom.html.ReactHTML.tr
import tasks.edit.taskListComponentBase
import utils.fallbackIfNotTeacher
import utils.timestampToDefaultValueString
import utils.useActionAwayOverlay
import web.cssom.*
import web.html.HTMLInputElement
import web.html.HTMLSelectElement
import web.html.InputType
import web.location.location
import kotlin.js.Date

class LessonEditComponent(
    var lessonId: Long? = null,
) : Component<Props, State>() {
    companion object {
        private val KNOWLEDGE_ELEMENT_ID = "knowledge-overlay"
        private val OPEN_KNOWLEDGE_SELECT_BUTTON = "open-knowledge-select-button"
    }

    private suspend fun fetchLesson(): LessonDto = LessonsApi.fetchLesson(lessonId!!)

    private suspend fun fetchLessonToKnowledgeInfos(): List<LessonToKnowledgeInfoWithKnowledgeBodyDto> =
        LessonsApi.fetchLessonToKnowledgeInfos(lessonId!!)

    private fun PropsWithClassName.borderCss() {
        css {
            border = Border(1.px, LineStyle.solid, Color("#000000"))
        }
    }

    override fun render(): ReactNode =
        FC<Props> { props ->
            var lessonState: LessonDto? by useState(null)
            var lessonToKnowledgeInfosState: List<LessonToKnowledgeInfoWithKnowledgeBodyDto> by useState(emptyList())
            val nameRef = useRef<HTMLInputElement>()
            val stateRef = useRef<HTMLSelectElement>()
            val publishAtRef = useRef<HTMLInputElement>()
            val taskNumberRef = useRef<HTMLInputElement>()
            val typeRef = useRef<HTMLSelectElement>()
            val timeToTestRef = useRef<HTMLInputElement>()
            var typeState: LessonDto.Type by useState(LessonDto.Type.REGULAR)
            var showOverlay: Boolean by useState(false)

            useEffectOnce {
                lessonId = URLSearchParams(location.search).get("id")?.toLong()

                MainScope().launch {
                    val lesson = fetchLesson()
                    lessonState = lesson
                    lessonToKnowledgeInfosState = fetchLessonToKnowledgeInfos()
                    fallbackIfNotTeacher()

                    typeState = lesson.type
                }
            }

            useActionAwayOverlay(KNOWLEDGE_ELEMENT_ID, setOf(OPEN_KNOWLEDGE_SELECT_BUTTON)) {
                showOverlay = false
            }

            div {
                className = if (showOverlay) ClassName("hidden-behind-overlay") else ClassName("")

                div {
                    +"Edit lesson"
                }

                div {
                    borderCss()
                    div {
                        +"Name"

                        input {
                            ref = nameRef
                            defaultValue = lessonState?.name ?: ""
                        }
                    }
                    div {
                        +"Publish at"

                        input {
                            type = InputType.datetimeLocal
                            ref = publishAtRef
                            defaultValue = lessonState?.publishAt?.timestampToDefaultValueString()
                        }
                    }
                    div {
                        +"Task number"

                        input {
                            type = InputType.number
                            ref = taskNumberRef
                            defaultValue = lessonState?.taskNumber
                        }
                    }
                    if (lessonState != null) {
                        div {
                            +"State"

                            stateSelect(stateRef, false, lessonState?.state)
                        }
                    }
                    if (lessonState != null) {
                        div {
                            +"Type"

                            typeSelect(typeRef, false, lessonState?.type) {
                                typeState = lessonTypeRefToEnum(typeRef)!!
                            }
                        }
                        if (typeState == LessonDto.Type.TEST) {
                            div {
                                +"Time to test in minutes"

                                timeToTestInput(
                                    timeToTestRef,
                                    lessonState
                                        ?.payload
                                        ?.let { it as? TestLessonPayloadDto }
                                        ?.timeToTest
                                        ?.div(60_000),
                                )
                            }
                        }
                    }
                    div {
                        button {
                            +"Save"
                            onClick = {
                                MainScope().launch {
                                    val type = lessonTypeRefToEnum(typeRef)!!
                                    val payload =
                                        when (type) {
                                            LessonDto.Type.REGULAR -> LessonPayloadDto.LessonPayloadStub
                                            LessonDto.Type.TEST ->
                                                TestLessonPayloadDto(
                                                    timeToTest =
                                                        timeToTestRef.current
                                                            ?.value
                                                            ?.toLong()
                                                            ?.let { it * 60_000 } ?: 0,
                                                )
                                        }

                                    LessonsApi.updateLesson(
                                        LessonDto(
                                            id = lessonId,
                                            name = nameRef.current?.value ?: "",
                                            publishAt = Date(publishAtRef.current?.value ?: "").getTime().toLong(),
                                            taskNumber = taskNumberRef.current?.value?.toInt() ?: 0,
                                            state = lessonStateRefToEnum(stateRef) ?: LessonDto.State.DRAFT,
                                            type = type,
                                            payload = payload,
                                        ),
                                    )
                                }
                            }
                        }
                        button {
                            +"Schedule publish"
                            onClick = {
                                MainScope().launch {
                                    try {
                                        LessonsApi.schedulePublishLesson(
                                            lessonId!!,
                                            Date(publishAtRef.current?.value ?: "").getTime().toLong(),
                                        )
                                    } catch (e: Exception) {
                                        window.alert(e.toString())
                                        throw e
                                    }
                                }
                            }
                        }
                    }
                }

                // Knowledge list
                div {
                    borderCss()

                    +"Lesson's knowledge list"

                    table {
                        css {
                            borderCollapse = BorderCollapse.separate
                            borderSpacing = 50.px
                        }

                        tr {
                            th {
                                +"Order number"
                            }
                            th {
                                +"Id"
                            }
                            th {
                                +"Name"
                            }
                            th {
                                +"Actions"
                            }
                        }
                        lessonToKnowledgeInfosState.sortedBy { it.orderNumber }.forEach { lessonToKnowledgeInfo ->
                            tr {
                                th {
                                    +lessonToKnowledgeInfo.orderNumber.toString()
                                }
                                th {
                                    button {
                                        +lessonToKnowledgeInfo.knowledge.id!!.toString()

                                        onClick = {
                                            window.open("/tasks/edit/?id=${lessonToKnowledgeInfo.knowledge.id!!}")
                                        }
                                    }
                                }
                                th {
                                    +lessonToKnowledgeInfo.knowledge.title
                                }
                                th {
                                    button {
                                        +"Move up"
                                        onClick = {
                                            lessonToKnowledgeInfosState =
                                                lessonToKnowledgeInfosState.map { lessonToKnowledgeInfoInner ->
                                                    when (lessonToKnowledgeInfoInner.orderNumber) {
                                                        lessonToKnowledgeInfo.orderNumber -> {
                                                            lessonToKnowledgeInfoInner.copy(
                                                                orderNumber =
                                                                    kotlin.math.max(
                                                                        1,
                                                                        lessonToKnowledgeInfoInner.orderNumber - 1,
                                                                    ),
                                                            )
                                                        }

                                                        lessonToKnowledgeInfo.orderNumber - 1 -> {
                                                            lessonToKnowledgeInfoInner.copy(
                                                                orderNumber =
                                                                    kotlin.math.min(
                                                                        lessonToKnowledgeInfosState.size,
                                                                        lessonToKnowledgeInfoInner.orderNumber + 1,
                                                                    ),
                                                            )
                                                        }

                                                        else -> {
                                                            lessonToKnowledgeInfoInner
                                                        }
                                                    }
                                                }
                                        }
                                    }
                                    button {
                                        +"Move down"
                                        onClick = {
                                            lessonToKnowledgeInfosState =
                                                lessonToKnowledgeInfosState.map { lessonToKnowledgeInfoInner ->
                                                    when (lessonToKnowledgeInfoInner.orderNumber) {
                                                        lessonToKnowledgeInfo.orderNumber -> {
                                                            lessonToKnowledgeInfoInner.copy(
                                                                orderNumber =
                                                                    kotlin.math.min(
                                                                        lessonToKnowledgeInfosState.size,
                                                                        lessonToKnowledgeInfoInner.orderNumber + 1,
                                                                    ),
                                                            )
                                                        }

                                                        lessonToKnowledgeInfo.orderNumber + 1 -> {
                                                            lessonToKnowledgeInfoInner.copy(
                                                                orderNumber =
                                                                    kotlin.math.max(
                                                                        1,
                                                                        lessonToKnowledgeInfoInner.orderNumber - 1,
                                                                    ),
                                                            )
                                                        }

                                                        else -> {
                                                            lessonToKnowledgeInfoInner
                                                        }
                                                    }
                                                }
                                        }
                                    }
                                    button {
                                        +"Delete"
                                        onClick = {
                                            lessonToKnowledgeInfosState =
                                                lessonToKnowledgeInfosState
                                                    .filter { lessonToKnowledgeInfoInner ->
                                                        lessonToKnowledgeInfoInner.orderNumber != lessonToKnowledgeInfo.orderNumber
                                                    }.map { lessonToKnowledgeInfoInner ->
                                                        if (lessonToKnowledgeInfoInner.orderNumber > lessonToKnowledgeInfo.orderNumber) {
                                                            console.log("lessonToKnowledgeInfoInner $lessonToKnowledgeInfoInner is greater")
                                                            lessonToKnowledgeInfoInner.copy(
                                                                orderNumber =
                                                                    lessonToKnowledgeInfoInner.orderNumber - 1,
                                                            )
                                                        } else {
                                                            console.log("lessonToKnowledgeInfoInner $lessonToKnowledgeInfoInner is lower")
                                                            lessonToKnowledgeInfoInner
                                                        }
                                                    }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    button {
                        id = OPEN_KNOWLEDGE_SELECT_BUTTON

                        +"Add knowledge"
                        onClick = {
                            showOverlay = true
                        }
                    }

                    button {
                        +"Save"
                        onClick = {
                            MainScope().launch {
                                LessonsApi.updateLessonToKnowledgeInfos(
                                    lessonId!!,
                                    lessonToKnowledgeInfosState.map { lessonToKnowledgeInfo ->
                                        LessonToKnowledgeInfoDto(
                                            lessonId = lessonToKnowledgeInfo.lessonId,
                                            knowledgeId = lessonToKnowledgeInfo.knowledge.id!!,
                                            orderNumber = lessonToKnowledgeInfo.orderNumber,
                                        )
                                    },
                                )
                            }
                        }
                    }
                }
            }

            div {
                className = if (showOverlay) ClassName("overlay") else ClassName("overlay-hidden")
                div {
                    className = ClassName("inner-overlay-div")

                    id = KNOWLEDGE_ELEMENT_ID

                    div {
                        taskListComponentBase<ChildrenBuilder>(props) { knowledge ->
                            return@taskListComponentBase {
                                button {
                                    onClick = {
                                        lessonToKnowledgeInfosState =
                                            lessonToKnowledgeInfosState +
                                            LessonToKnowledgeInfoWithKnowledgeBodyDto(
                                                lessonId = lessonId!!,
                                                knowledge = knowledge,
                                                orderNumber = lessonToKnowledgeInfosState.size + 1,
                                            )
                                        showOverlay = false
                                    }

                                    +"Add"
                                }
                            }
                        }
                    }
                }
            }
        }.create()
}
