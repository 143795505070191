package client

import adm.groups.GroupsApi
import adm.groups.StudentsGroupDto
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.main
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.th
import react.dom.html.ReactHTML.title
import react.dom.html.ReactHTML.tr
import react.router.dom.Link
import react.useEffectOnce
import react.useState

val GroupsComponent = FC<Props> {
    var groups: List<StudentsGroupDto>? by useState(null)

    useEffectOnce {
        MainScope().launch {
            groups = GroupsApi.fetchMyGroups()
        }
    }

    title {
        +"Ваши группы"
    }

    main {
        div {
            h1 {
                +"Ваши группы"
            }

            if (groups == null) {
                +"Загрузка..."
            } else {
                table {
                    tr {
                        th {
                            +"Название"
                        }
                    }
                    for (group in groups!!) {
                        tr {
                            td {
                                Link {
                                    +group.name

                                    to = "/groups/info?groupId=${group.id}"
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
