package adm.groups

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.textarea
import react.useEffectOnce
import react.useRef
import utils.fallbackIfNotAdmin
import web.html.HTMLInputElement
import web.html.HTMLSelectElement
import web.html.HTMLTextAreaElement
import web.location.location

val CreateGroupComponent = FC<Props> {
    val nameRef = useRef<HTMLInputElement>()
    val typeRef = useRef<HTMLSelectElement>()
    val descriptionRef = useRef<HTMLTextAreaElement>()
    val stateRef = useRef<HTMLSelectElement>()

    useEffectOnce {
        MainScope().launch {
            fallbackIfNotAdmin()
        }
    }

    +"Create group"

    div {
        +"Name"
        input {
            ref = nameRef
        }
    }
    div {
        +"Type"
        selectStudentsGroupType(typeRef, false)
    }
    div {
        +"State"
        selectStudentsGroupState(stateRef, false)
    }
    div {
        +"Description"
        textarea {
            ref = descriptionRef
        }
    }

    button {
        +"Create"
        onClick = {
            MainScope().launch {
                val name = nameRef.current?.value!!
                val type = studentsGroupTypeRefToDto(typeRef)!!
                val state = studentsGroupStateRefToDto(stateRef)!!
                val description = descriptionRef.current?.value!!

                val result = GroupsApi.createGroup(
                    StudentsGroupDto(
                        id = null,
                        name = name,
                        type = type,
                        state = state,
                        description = description,
                    ),
                )

                location.href = "/admin/groups/edit/?id=${result.id}"
            }
        }
    }
}
