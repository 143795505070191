package utils.experiments

import auth.authRetryFetchWrapper
import configuration.Conf
import kotlinx.coroutines.await
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import org.w3c.dom.url.URLSearchParams

enum class ExperimentNamespaceDto {
    ANY,

    FEEDBACK_TAB,
}

enum class ExperimentEntityTypeDto {
    USER_ID,
    GROUP_ID,
}

@Serializable
data class ExperimentAccessibilityHashDto(
    val experimentId: Long,
    val entityType: ExperimentEntityTypeDto,
    val hashStart: Double,
    val hashEnd: Double,
)

@Serializable
data class ExperimentAccessibilityDto(
    val experimentId: Long,
    val entityType: ExperimentEntityTypeDto,
    val entityId: Long,
)

@Serializable
data class ExperimentDto(
    val id: Long?,
    val name: ExperimentNamespaceDto,
    val description: String?,
    val createdAt: Long?,
    val accessibility: List<ExperimentAccessibilityDto>? = null,
    val hashAccessibility: List<ExperimentAccessibilityHashDto>? = null,
)

@Serializable
data class IsInExperimentResponseDto(
    val result: Boolean,
)

suspend fun checkInExperiment(experimentName: ExperimentNamespaceDto, entityType: ExperimentEntityTypeDto, entityId: Long): Boolean {
    val searchParams = URLSearchParams()
    searchParams.append("experimentName", experimentName.name)
    searchParams.append("entityType", entityType.name)
    searchParams.append("entityId", entityId.toString())

    val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/experiments/isInExperiment?$searchParams")
        .text()
        .await()

    val result = try {
        Json.decodeFromString<IsInExperimentResponseDto>(response).result
    } catch (e: Exception) {
        console.log(e)
        false
    }

    return result
}