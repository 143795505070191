package tasks.edit

import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import org.w3c.dom.url.URL
import react.*
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.th
import react.dom.html.ReactHTML.tr
import web.html.HTMLInputElement
import web.html.HTMLSelectElement
import web.location.location

val TaskListComponent = FC<Props> {
    taskListComponentBase<ChildrenBuilder>(it) { knowledge ->
        return@taskListComponentBase {
            button {
                onClick = {
                    val url = URL(window.location.toString())
                    url.pathname += if (url.pathname.last() == '/') {
                        "edit/"
                    } else {
                        "/edit/"
                    }
                    url.searchParams.set("id", knowledge.id.toString())
                    location.href = url.toString()
                }

                +"Edit"
            }
        }
    }
}

fun <P : ChildrenBuilder> ChildrenBuilder.taskListComponentBase(
    props: Props,
    actionsColumn: (KnowledgeDto) -> (@ReactDsl P.() -> Unit),
) {
    val difficulty = useRef<HTMLSelectElement>()
    val taskNumber = useRef<HTMLInputElement>()
    var knowledgeDtos: List<KnowledgeDto> by useState(emptyList())
    var pageState: Int by useState(0)

    selectDifficulty(difficulty)
    selectTaskNumber(taskNumber)

    button {
        onClick = {
            MainScope().launch {
                knowledgeDtos = fetchKnowledgeList(
                    difficulty.current?.value?.let {
                        try {
                            KnowledgeDto.Difficulty.valueOf(it)
                        } catch (e: Exception) {
                            null
                        }
                    },
                    try {
                        taskNumber.current?.value?.toLong()
                    } catch (e: Exception) {
                        null
                    },
                    pageState,
                )
            }
        }

        +"Search"
    }

    button {
        onClick = {
            window.open("/tasks/edit/")
        }

        +"Create"
    }
    table {
        tr {
            th {
                +"Id"
            }
            th {
                +"Title"
            }
            th {
                +"Difficulty"
            }
            th {
                +"Task number"
            }
            th {
                +"Actions"
            }
        }

        for (knowledge in knowledgeDtos.sortedBy { it.id }) {
            tr {
                td {
                    +"${knowledge.id}"
                }
                td {
                    +knowledge.title
                }
                td {
                    +"${knowledge.difficulty}"
                }
                td {
                    +"${knowledge.taskNumber}"
                }
                td(actionsColumn(knowledge))
            }
        }
    }

    div {
        // pagination
        button {
            +"Prev"
            onClick = {
                if (pageState > 0) {
                    MainScope().launch {
                        knowledgeDtos = fetchKnowledgeList(
                            difficulty.current?.value?.let {
                                try {
                                    KnowledgeDto.Difficulty.valueOf(it)
                                } catch (e: Exception) {
                                    null
                                }
                            },
                            try {
                                taskNumber.current?.value?.toLong()
                            } catch (e: Exception) {
                                null
                            },
                            pageState - 1,
                        )
                        pageState--
                    }
                }
            }
        }
        +"Page: $pageState"
        button {
            +"Next"
            onClick = {
                MainScope().launch {
                    knowledgeDtos = fetchKnowledgeList(
                        difficulty.current?.value?.let {
                            try {
                                KnowledgeDto.Difficulty.valueOf(it)
                            } catch (e: Exception) {
                                null
                            }
                        },
                        try {
                            taskNumber.current?.value?.toLong()
                        } catch (e: Exception) {
                            null
                        },
                        pageState + 1,
                    )
                    pageState++
                }
            }
        }
    }
}
