package tasks.edit

import auth.authRetryFetchWrapper
import configuration.Conf
import kotlinx.coroutines.await
import kotlinx.serialization.Serializable
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import org.w3c.dom.url.URLSearchParams
import org.w3c.fetch.Headers
import org.w3c.fetch.RequestInit
import org.w3c.files.File
import org.w3c.xhr.FormData

@Serializable
data class AttachmentDto(
    val id: Long?,
    val knowledgeId: Long,
    val name: String,
    val contentType: String,
    val path: String,
    val hash: String?,
)

@Serializable
data class KnowledgeDto(
    val id: Long?,
    val title: String,
    val body: String,
    val createdBy: Long,
    val createdAt: Long,
    val updatedBy: Long,
    val updatedAt: Long,
    val difficulty: Difficulty,
    val taskNumber: Int,
    val type: Type,
    val state: State,
    val answer: String?,
) {
    enum class Difficulty {
        EASY,
        MEDIUM,
        HARD,
    }

    enum class Type {
        THEORY,
        TASK_VISIBLE_ANSWER,
        TASK_HIDDEN_ANSWER,
    }

    enum class State {
        DRAFT,
        PUBLISHED,
    }
}

suspend fun fetchKnowledge(id: Long): KnowledgeDto {
    val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/knowledge/$id")
        .text()
        .await()

    return response
        .let { Json.decodeFromString(KnowledgeDto.serializer(), it) }
}

suspend fun createKnowledge(knowledge: KnowledgeDto): Long {
    val response = authRetryFetchWrapper(
        "${Conf.globalUrlBase}/v0/knowledge/",
        RequestInit(
            method = "POST",
            headers = Headers().apply {
                set("Content-Type", "application/json")
            },
            body = Json.encodeToString(KnowledgeDto.serializer(), knowledge),
        ),
    )
        .text()
        .await()

    return response
        .let { Json.decodeFromString(it) }
}

suspend fun updateKnowledge(knowledge: KnowledgeDto) {
    authRetryFetchWrapper(
        "${Conf.globalUrlBase}/v0/knowledge/${knowledge.id}",
        RequestInit(
            method = "PUT",
            headers = Headers().apply {
                set("Content-Type", "application/json")
            },
            body = Json.encodeToString(KnowledgeDto.serializer(), knowledge),
        ),
    )
}

suspend fun fetchKnowledgeList(
    difficulty: KnowledgeDto.Difficulty? = null,
    taskNumber: Long? = null,
    page: Int? = null,
): List<KnowledgeDto> {
    val searchParams = URLSearchParams()
        .apply {
            difficulty?.let { append("difficulty", it.name) }
            taskNumber?.let { append("taskNumber", it.toString()) }
            page?.let { append("page", it.toString()) }
        }

    val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/knowledge/getAllKnowledge?$searchParams")
        .text()
        .await()

    return response
        .let { Json.decodeFromString(ListSerializer(KnowledgeDto.serializer()), it) }
}

suspend fun sendFile(file: File, knowledgeId: Long): Long {
    val formData = FormData()
    formData.append("file", file)

    val response = authRetryFetchWrapper(
        "${Conf.globalUrlBase}/v0/knowledge/$knowledgeId/attachment",
        RequestInit(
            method = "POST",
            body = formData,
        ),
    )
        .text()
        .await()

    return response
        .let { Json.decodeFromString(it) }
}

suspend fun getAttachments(knowledgeId: Long): List<AttachmentDto> {
    val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/knowledge/$knowledgeId/attachment")
        .text()
        .await()

    return response
        .let { Json.decodeFromString(ListSerializer(AttachmentDto.serializer()), it) }
}

suspend fun getAttachment(knowledgeId: Long, attachmentId: Long): AttachmentDto {
    val response = authRetryFetchWrapper("${Conf.globalUrlBase}/v0/knowledge/$knowledgeId/attachment/$attachmentId")
        .text()
        .await()

    return response
        .let { Json.decodeFromString(AttachmentDto.serializer(), it) }
}
