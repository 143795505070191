package feedback

import auth.authRetryFetchWrapper
import configuration.Conf
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.w3c.fetch.RequestInit
import web.http.Headers

enum class FeedbackTypeDto {
    FEEDBACK,
}

@Serializable
data class FeedbackDto(
    val id: Long?,
    val type: FeedbackTypeDto,
    val text: String,
    val createdAt: Long,
    val userId: Long,
)

object FeedbackApi {
    suspend fun sendFeedback(feedbackDto: FeedbackDto) {
        val response = authRetryFetchWrapper(
            "${Conf.globalUrlBase}/v0/feedback/",
            RequestInit(
                method = "POST",
                headers = Headers().apply {
                    append("Content-Type", "application/json")
                },
                body = Json.encodeToString(feedbackDto),
            ),
        )

        if (!response.ok) {
            throw Exception("Failed to send feedback")
        }
    }
}