package feedback

import js.core.jso
import react.create
import react.router.RouteObject

val feedbackRoutes = jso<RouteObject> {
    path = "feedback/"
    index = true
    element = FeedbackComponent.create()
}