package adm.groups

import adm.lessons.lessonListComponentBuilder
import adm.users.userListComponentBase
import auth.UserDto
import auth.UserDtoWithRoles
import emotion.react.css
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.joinAll
import kotlinx.coroutines.launch
import react.*
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.textarea
import react.dom.html.ReactHTML.th
import react.dom.html.ReactHTML.tr
import utils.fallbackIfNotAdmin
import utils.timestampToDefaultValueString
import utils.useActionAwayOverlay
import web.cssom.*
import web.html.HTMLInputElement
import web.html.HTMLSelectElement
import web.html.HTMLTextAreaElement
import web.html.InputType
import web.window.window
import kotlin.js.Date

class GroupsEditComponent(
    private val groupId: Long?,
) : Component<Props, State>() {
    companion object {
        private const val CURATOR_OVERLAY_ID = "curator-overlay"
        private const val STUDENT_CHOOSE_OVERLAY_ID = "student-choose-overlay"
        private const val STUDENT_EDIT_OVERLAY_ID = "student-edit-overlay"
        private const val LESSON_ADD_OVERLAY_ID = "lesson-add-overlay"

        private const val BUTTON_SHOW_ADD_CURATOR_OVERLAY_ID = "show-add-curator-overlay"
        private const val BUTTON_SHOW_CHOOSE_STUDENT_OVERLAY_ID = "show-choose-student-overlay"
        private const val BUTTON_SHOW_EDIT_STUDENT_OVERLAY_ID = "show-edit-student-overlay"
        private const val BUTTON_SHOW_ADD_LESSON_OVERLAY_ID = "show-add-lesson-overlay"
    }

    private fun PropsWithClassName.borderCss() {
        css {
            border = Border(1.px, LineStyle.solid, Color("#000000"))
        }
    }

    override fun render(): ReactNode {
        return FC<Props> {
            var groupState: StudentsGroupWithStudentsAndCuratorsDto? by useState(null)
            val nameRef = useRef<HTMLInputElement>()
            val typeRef = useRef<HTMLSelectElement>()
            val descriptionRef = useRef<HTMLTextAreaElement>()
            val stateRef = useRef<HTMLSelectElement>()
            var showAddCuratorOverlay: Boolean by useState(false)
            var showChooseStudentOverlay: Boolean by useState(false)
            var showEditStudentOverlay: Boolean by useState(false)
            var showAddLessonOverlay: Boolean by useState(false)
            var lessonsToGroup: List<LessonToGroupDto> by useState(emptyList())
            val paymentAtRef = useRef<HTMLInputElement>()
            val paymentTypeRef = useRef<HTMLSelectElement>()
            val paymentStateRef = useRef<HTMLSelectElement>()
            val hasAccessRef = useRef<HTMLInputElement>()
            val paymentExpiresAtRef = useRef<HTMLInputElement>()
            var chooseStudentUser: UserDtoWithRoles? by useState(null)
            var userToGroupConnectionInfoDto: IUserToGroupConnectionInfoDto? by useState(null)
            var isGroupDefault: Boolean by useState(false)

            fun cleanStudentRefs() {
                paymentAtRef.current?.value = ""
                paymentTypeRef.current?.value = IUserToGroupConnectionInfoDto.PaymentType.FREE.name
                paymentStateRef.current?.value = IUserToGroupConnectionInfoDto.PaymentState.NOT_PAID.name
                hasAccessRef.current?.checked = false
                paymentExpiresAtRef.current?.value = ""

                userToGroupConnectionInfoDto = null
                chooseStudentUser = null
            }

            fun fillRefs() {
                paymentAtRef.current?.value =
                    userToGroupConnectionInfoDto?.paymentAt?.timestampToDefaultValueString() ?: ""
                paymentTypeRef.current?.value = userToGroupConnectionInfoDto?.paymentType?.name
                    ?: IUserToGroupConnectionInfoDto.PaymentType.FREE.name
                paymentStateRef.current?.value = userToGroupConnectionInfoDto?.paymentState?.name
                    ?: IUserToGroupConnectionInfoDto.PaymentState.NOT_PAID.name
                hasAccessRef.current?.checked = userToGroupConnectionInfoDto?.hasAccess ?: false
                paymentExpiresAtRef.current?.value =
                    userToGroupConnectionInfoDto?.paymentExpiresAt?.timestampToDefaultValueString() ?: ""
            }

            useActionAwayOverlay(
                CURATOR_OVERLAY_ID,
                setOf(BUTTON_SHOW_ADD_CURATOR_OVERLAY_ID),
            ) { showAddCuratorOverlay = false }
            useActionAwayOverlay(
                STUDENT_CHOOSE_OVERLAY_ID,
                setOf(BUTTON_SHOW_CHOOSE_STUDENT_OVERLAY_ID),
            ) { showChooseStudentOverlay = false }
            useActionAwayOverlay(
                LESSON_ADD_OVERLAY_ID,
                setOf(BUTTON_SHOW_ADD_LESSON_OVERLAY_ID),
            ) { showAddLessonOverlay = false }
            useActionAwayOverlay(
                STUDENT_EDIT_OVERLAY_ID,
                setOf(BUTTON_SHOW_EDIT_STUDENT_OVERLAY_ID),
            ) {
                showEditStudentOverlay = false
                cleanStudentRefs()
            }

            useEffectOnce {
                MainScope().launch {
                    val jobs = listOf(
                        launch { groupState = GroupsApi.fetchGroupWithStudentsAndCurators(groupId!!) },
                        launch { lessonsToGroup = GroupsApi.getGroupLessons(groupId!!) },

                        launch {
                            val defaultGroup = GroupsApi.getDefault()
                            isGroupDefault = defaultGroup.id == groupId
                        },

                        launch {
                            fallbackIfNotAdmin()
                        },
                    )

                    jobs.joinAll()
                }
            }

            fun removeCurator(curator: UserDto) {
                MainScope().launch {
                    GroupsApi.removeCuratorFromGroup(groupId!!, curator.id)
                    groupState = groupState!!.copy(
                        curators = groupState!!.curators.filter { it.id != curator.id },
                    )
                }
            }

            fun groupSaveAction() {
                MainScope().launch {
                    val name = nameRef.current?.value!!
                    val type = studentsGroupTypeRefToDto(typeRef)!!
                    val state = studentsGroupStateRefToDto(stateRef)!!
                    val description = descriptionRef.current?.value!!

                    GroupsApi.updateGroup(
                        StudentsGroupDto(
                            id = groupId,
                            name = name,
                            type = type,
                            state = state,
                            description = description,
                        ),
                    )
                }
            }

            fun ChildrenBuilder.typeAndStateDiv() {
                div {
                    +"Type"
                    if (groupState != null) {
                        selectStudentsGroupType(typeRef, false, groupState!!.type)
                    } else {
                        selectStudentsGroupType(typeRef, false)
                    }
                }
                div {
                    +"State"
                    if (groupState != null) {
                        selectStudentsGroupState(stateRef, false, groupState!!.state)
                    } else {
                        selectStudentsGroupState(stateRef, false)
                    }
                }
            }

            fun ChildrenBuilder.groupInfoDiv() {
                div {
                    borderCss()

                    div {
                        +"Name"
                        input {
                            ref = nameRef
                            defaultValue = groupState?.name
                        }
                    }
                    typeAndStateDiv()
                    div {
                        +"Description"
                        textarea {
                            ref = descriptionRef
                            defaultValue = groupState?.description
                        }
                    }
                    button {
                        +"Save"
                        onClick = { groupSaveAction() }
                    }

                    button {
                        +"Set default"
                        disabled = isGroupDefault
                        onClick = {
                            MainScope().launch {
                                GroupsApi.setDefault(groupId!!)
                                isGroupDefault = true
                            }
                        }
                    }
                }
            }

            fun ChildrenBuilder.curatorsTable() {
                tr {
                    th {
                        +"Id"
                    }
                    th {
                        +"Name"
                    }
                    th {
                        +"Actions"
                    }
                }

                groupState?.curators?.forEach { curator ->
                    tr {
                        td {
                            button {
                                +curator.id.toString()

                                onClick = {
                                    window.open("/admin/users/edit/?id=${curator.id}")
                                }
                            }
                        }
                        td {
                            +curator.username
                        }
                        td {
                            button {
                                +"Remove"
                                onClick = { removeCurator(curator) }
                            }
                        }
                    }
                }
            }

            fun ChildrenBuilder.lessonsTable() {
                tr {
                    th {
                        +"Order number"
                    }
                    th {
                        +"Id"
                    }
                    th {
                        +"Name"
                    }
                    th {
                        +"Publish at"
                    }
                    th {
                        +"Task number"
                    }
                    th {
                        +"State"
                    }
                    th {
                        +"Actions"
                    }
                }

                lessonsToGroup.sortedBy { it.orderNumber }
                    .forEach { lessonToGroup ->
                        tr {
                            td {
                                +lessonToGroup.orderNumber.toString()
                            }
                            td {
                                button {
                                    +lessonToGroup.lesson?.id.toString()

                                    onClick = {
                                        window.open("/admin/lessons/edit/?id=${lessonToGroup.lessonId}")
                                    }
                                }
                            }
                            td {
                                +lessonToGroup.lesson?.name
                            }
                            td {
                                +lessonToGroup.lesson?.publishAt?.timestampToDefaultValueString()
                            }
                            td {
                                +lessonToGroup.lesson?.taskNumber.toString()
                            }
                            td {
                                +lessonToGroup.lesson?.state?.name
                            }
                            td {
                                button {
                                    +"Move up"
                                    onClick = {
                                        MainScope().launch {
                                            val lessonToGroupDto =
                                                lessonsToGroup.find { it.lessonId == lessonToGroup.lessonId }!!
                                            val prevLessonToGroupDto =
                                                lessonsToGroup.find { it.orderNumber == lessonToGroupDto.orderNumber - 1 }!!

                                            lessonsToGroup = lessonsToGroup.map { currLessonToGroupDto ->
                                                when (currLessonToGroupDto.lessonId) {
                                                    lessonToGroupDto.lessonId -> {
                                                        lessonToGroupDto.copy(orderNumber = lessonToGroupDto.orderNumber - 1)
                                                    }

                                                    prevLessonToGroupDto.lessonId -> {
                                                        prevLessonToGroupDto.copy(orderNumber = prevLessonToGroupDto.orderNumber + 1)
                                                    }

                                                    else -> {
                                                        currLessonToGroupDto
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                button {
                                    +"Move down"
                                    onClick = {
                                        MainScope().launch {
                                            val lessonToGroupDto =
                                                lessonsToGroup.find { it.lessonId == lessonToGroup.lessonId }!!
                                            val nextLessonToGroupDto =
                                                lessonsToGroup.find { it.orderNumber == lessonToGroupDto.orderNumber + 1 }!!

                                            lessonsToGroup = lessonsToGroup.map { currLessonToGroupDto ->
                                                when (currLessonToGroupDto.lessonId) {
                                                    lessonToGroupDto.lessonId -> {
                                                        lessonToGroupDto.copy(orderNumber = lessonToGroupDto.orderNumber + 1)
                                                    }

                                                    nextLessonToGroupDto.lessonId -> {
                                                        nextLessonToGroupDto.copy(orderNumber = nextLessonToGroupDto.orderNumber - 1)
                                                    }

                                                    else -> {
                                                        currLessonToGroupDto
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                button {
                                    +"Remove"
                                    onClick = {
                                        MainScope().launch {
                                            lessonsToGroup =
                                                lessonsToGroup.filter { it.lessonId != lessonToGroup.lessonId }
                                        }
                                    }
                                }
                            }
                        }
                    }
            }

            fun ChildrenBuilder.studentsTable() {
                console.log(groupState?.students?.toString())

                tr {
                    th {
                        +"Id"
                    }
                    th {
                        +"Name"
                    }
                    th {
                        +"Payment at"
                    }
                    th {
                        +"Payment type"
                    }
                    th {
                        +"Payment state"
                    }
                    th {
                        +"Has access"
                    }
                    th {
                        +"Payment expires at"
                    }
                    th {
                        +"Actions"
                    }
                }

                groupState?.students?.forEach { student ->
                    tr {
                        td {
                            button {
                                +student.user.id.toString()

                                onClick = {
                                    window.open("/admin/users/edit/?id=${student.user.id}")
                                }
                            }
                        }
                        td {
                            +student.user.username
                        }
                        td {
                            +student.paymentAt.timestampToDefaultValueString()
                        }
                        td {
                            +student.paymentType.name
                        }
                        td {
                            +student.paymentState.name
                        }
                        td {
                            +student.hasAccess.toString()
                        }
                        td {
                            +student.paymentExpiresAt.timestampToDefaultValueString()
                        }
                        td {
                            button {
                                +"Remove"
                                onClick = {
                                    MainScope().launch {
                                        GroupsApi.removeStudentFromGroup(groupId!!, student.user.id)
                                        groupState = groupState!!.copy(
                                            students = groupState!!.students.filter { it.user.id != student.user.id },
                                        )
                                    }
                                }
                            }
                            button {
                                id = BUTTON_SHOW_EDIT_STUDENT_OVERLAY_ID
                                +"Edit"

                                onClick = {
                                    userToGroupConnectionInfoDto = student
                                    chooseStudentUser = UserDtoWithRoles(
                                        id = student.user.id!!,
                                        username = student.user.username,
                                        roles = emptyList(),
                                    )
                                    showEditStudentOverlay = true
                                }
                            }
                        }
                    }
                }
            }

            fun curatorAddActionInOverlay(user: UserDtoWithRoles) {
                MainScope().launch {
                    GroupsApi.addCuratorToGroup(groupId!!, user.id)
                }

                groupState = groupState!!.copy(
                    curators = groupState!!.curators + UserDto(
                        id = user.id,
                        username = user.username,
                    ),
                )
                showAddCuratorOverlay = false
            }

            fun ChildrenBuilder.curatorsListDiv() {
                div {
                    borderCss()
                    +"Curators"
                    table {
                        css {
                            borderCollapse = BorderCollapse.separate
                            borderSpacing = 50.px
                        }

                        curatorsTable()
                    }

                    button {
                        id = BUTTON_SHOW_ADD_CURATOR_OVERLAY_ID
                        +"Add curator"
                        onClick = {
                            showAddCuratorOverlay = true
                        }
                    }
                }
            }

            fun ChildrenBuilder.lessonsListDiv() {
                div {
                    borderCss()
                    +"Lessons"
                    table {
                        css {
                            borderCollapse = BorderCollapse.separate
                            borderSpacing = 50.px
                        }

                        lessonsTable()
                    }

                    button {
                        id = BUTTON_SHOW_ADD_LESSON_OVERLAY_ID
                        +"Add lesson"
                        onClick = {
                            showAddLessonOverlay = true
                        }
                    }

                    button {
                        +"Save lessons"
                        onClick = {
                            MainScope().launch {
                                GroupsApi.recreateLessons(groupId!!, lessonsToGroup)
                            }
                        }
                    }
                }
            }

            fun ChildrenBuilder.studentsListDiv() {
                div {
                    borderCss()
                    +"Students"

                    table {
                        css {
                            borderCollapse = BorderCollapse.separate
                            borderSpacing = 50.px
                        }

                        studentsTable()
                    }

                    button {
                        id = BUTTON_SHOW_CHOOSE_STUDENT_OVERLAY_ID
                        +"Add student"
                        onClick = {
                            showChooseStudentOverlay = true
                        }
                    }
                }
            }

            fun curatorOverlay() {
                div {
                    className = if (showAddCuratorOverlay) ClassName("overlay") else ClassName("overlay-hidden")

                    div {
                        className = ClassName("inner-overlay-div")
                        id = CURATOR_OVERLAY_ID

                        div {
                            +"Add curator"

                            userListComponentBase<ChildrenBuilder>(props) { user ->
                                {
                                    button {
                                        +"Add"
                                        onClick = { curatorAddActionInOverlay(user) }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            fun performSaveStudentToGroup() {
                MainScope().launch {
                    val user = chooseStudentUser!!

                    val newUserToGroupConnectionInfoDto = UserToGroupConnectionInfoWithUserDto(
                        userId = user.id,
                        groupId = groupId!!,
                        paymentAt = paymentAtRef.current?.value?.let {
                            Date(it).getTime().toLong()
                        } ?: 0,
                        paymentType = paymentTypeRefToDto(paymentTypeRef)
                            ?: IUserToGroupConnectionInfoDto.PaymentType.FREE,
                        paymentState = paymentStateRefToDto(paymentStateRef)
                            ?: IUserToGroupConnectionInfoDto.PaymentState.NOT_PAID,
                        hasAccess = hasAccessRef.current?.checked ?: false,
                        paymentExpiresAt = paymentExpiresAtRef.current?.value?.let {
                            Date(
                                it,
                            ).getTime().toLong()
                        } ?: 0,

                        user = UserDto(user.id, user.username),
                    )

                    groupState = if (userToGroupConnectionInfoDto == null) {
                        GroupsApi.addStudentToGroup(
                            groupId,
                            newUserToGroupConnectionInfoDto,
                        )

                        groupState!!.copy(students = groupState!!.students + newUserToGroupConnectionInfoDto)
                    } else {
                        GroupsApi.updateStudentToGroup(
                            groupId,
                            newUserToGroupConnectionInfoDto,
                        )

                        groupState!!.copy(
                            students = groupState!!.students.map { currUser ->
                                if (currUser.user.id == user.id) {
                                    newUserToGroupConnectionInfoDto
                                } else {
                                    currUser
                                }
                            },
                        )
                    }

                    cleanStudentRefs()
                }
            }

            fun ChildrenBuilder.editStudentToGroup() {
                if (userToGroupConnectionInfoDto == null) {
                    +"Add student"
                } else {
                    +"Edit student"
                }

                div {
                    +"Payment at"
                    input {
                        type = InputType.datetimeLocal
                        ref = paymentAtRef
                        defaultValue = userToGroupConnectionInfoDto?.paymentAt?.timestampToDefaultValueString()
                    }
                }

                div {
                    +"Payment type"
                    if (userToGroupConnectionInfoDto != null) {
                        selectPaymentType(paymentTypeRef, false, userToGroupConnectionInfoDto!!.paymentType)
                    } else {
                        selectPaymentType(paymentTypeRef, false)
                    }
                }

                div {
                    +"Payment state"
                    if (userToGroupConnectionInfoDto != null) {
                        selectPaymentState(paymentStateRef, false, userToGroupConnectionInfoDto!!.paymentState)
                    } else {
                        selectPaymentState(paymentStateRef, false)
                    }
                }

                div {
                    +"Has access"
                    input {
                        type = InputType.checkbox
                        ref = hasAccessRef
                        defaultValue = userToGroupConnectionInfoDto?.hasAccess?.toString()
                    }
                }

                div {
                    +"Payment expires at"
                    input {
                        type = InputType.datetimeLocal
                        ref = paymentExpiresAtRef
                        defaultValue = userToGroupConnectionInfoDto?.paymentExpiresAt?.timestampToDefaultValueString()
                    }
                }

                button {
                    +"Save"
                    onClick = {
                        performSaveStudentToGroup()
                        showEditStudentOverlay = false
                    }
                }
            }

            fun ChildrenBuilder.chooseStudentDiv() {
                +"Choose student"

                userListComponentBase<ChildrenBuilder>(props) { user ->
                    {
                        button {
                            id = BUTTON_SHOW_EDIT_STUDENT_OVERLAY_ID

                            +"Add"
                            onClick = {
                                showChooseStudentOverlay = false
                                showEditStudentOverlay = true
                                chooseStudentUser = user
                            }
                        }
                    }
                }
            }

            fun ChildrenBuilder.lessonAddDiv() {
                +"Add lesson"

                lessonListComponentBuilder(props) { lesson ->
                    {
                        button {
                            +"Add"
                            onClick = {
                                MainScope().launch {
                                    lessonsToGroup = lessonsToGroup + LessonToGroupDto(
                                        lessonId = lesson.id,
                                        groupId = groupId,
                                        orderNumber = lessonsToGroup.size + 1,
                                        lesson = lesson,
                                    )
                                }
                            }
                        }
                    }
                }
            }

            fun studentsChooseOverlay() {
                div {
                    className = if (showChooseStudentOverlay) ClassName("overlay") else ClassName("overlay-hidden")

                    div {
                        id = STUDENT_CHOOSE_OVERLAY_ID
                        className = ClassName("inner-overlay-div")
                        div {
                            chooseStudentDiv()
                        }
                    }
                }
            }

            fun studentEditOverlay() {
                div {
                    className = if (showEditStudentOverlay) ClassName("overlay") else ClassName("overlay-hidden")

                    fillRefs()

                    div {
                        id = STUDENT_EDIT_OVERLAY_ID
                        className = ClassName("inner-overlay-div")
                        div {
                            editStudentToGroup()
                        }
                    }
                }
            }

            fun addLessonOverlay() {
                div {
                    className = if (showAddLessonOverlay) ClassName("overlay") else ClassName("overlay-hidden")

                    div {
                        id = LESSON_ADD_OVERLAY_ID
                        className = ClassName("inner-overlay-div")
                        div {
                            +"Add lesson"

                            lessonAddDiv()
                        }
                    }
                }
            }

            fun mainComponentBody() {
                +"Edit group"

                div {
                    className =
                        if (showAddCuratorOverlay || showChooseStudentOverlay || showEditStudentOverlay || showEditStudentOverlay) {
                            ClassName(
                                "hidden-behind-overlay",
                            )
                        } else {
                            null
                        }

                    groupInfoDiv()

                    curatorsListDiv()

                    studentsListDiv()

                    lessonsListDiv()
                }

                curatorOverlay()

                studentsChooseOverlay()

                studentEditOverlay()

                addLessonOverlay()
            }

            mainComponentBody()
        }.create()
    }
}
